import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as EditIcon } from "../../assests/images/edit_icon.svg";
import { ReactComponent as ChangeCategoryIcon } from "../../assests/images/changeCategory.svg";
import { ReactComponent as CloseRuleIcon } from "../../assests/images/icons/delete-rule-icon.svg";
import { Popover, PopoverBody } from "reactstrap";
import {
  categoryBackgroundCss,
  categoryColorForAll,
} from "../../utilities/Statics";
import { ReactComponent as Add_round_cross } from "../../assests/images/icons/Add_round_cross.svg";

const ListingCategories = ({
  categories,
  oneOffCategory,
  position,
  transactionId,
  updateTransactionCategory,
  togglePopover,
  popoverOpen,
  handleChange,
  transactionType,
  updateCategoriesInBulk,
  isSelectionMode,
  isPendingTransaction,
  isNewCategoryPopup = true,
  categoryTitle,
  categorySubTitle,
  editCategoryOnTap,
  setSelectedCategory,
  isEdit,
  setIsEdit,
  isEditButton,
  categoryPopoverHeading,
  isIncomeCategoryChange,
  setIsIncomeCategoryChange,
}) => {
  const popoverRef = useRef(null);
  const handleCategoryClick = (category) => {
    if (isEdit) {
      setSelectedCategory(category);
      editCategoryOnTap();
    } else {
      if (isSelectionMode) {
        updateCategoriesInBulk(category.userCategoryId, category.type);
      } else {
        updateTransactionCategory(
          transactionId,
          category.userCategoryId,
          category.type
        );
      }
      togglePopover(transactionId);
    }
  };
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);

  // Close the popover if clicked outside
  const handleClickOutside = (event) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      popoverOpen[transactionId]
    ) {
      togglePopover(transactionId);
    }
  };

  const handleEditCategory = () => {
    setIsEdit((prevState) => !prevState);
  };

  const handleChangeCategory = () => {
    setIsIncomeCategoryChange(!isIncomeCategoryChange);
  };

  const checkNotEditable = (type, isEdit) => {
    if (isEdit) {
      return type !== "ONE-OFF" && type !== null;
    }
    return true;
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverOpen, transactionId]);

  return (
    <>
      {!isPendingTransaction && (
        <Popover
          className="custom-popover"
          placement="bottom"
          isOpen={popoverOpen[transactionId] || false}
          target={`Popover-${transactionId}`}
          toggle={() => togglePopover(transactionId)}
          innerRef={popoverRef}
        >
          <PopoverBody>
            <div
              className="transaction-categories"
              style={{ top: position?.top, left: position?.left }}
            >
              <div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between"}}>
              <div className="bold popover-live">
                {categoryTitle || !isEdit
                  ? `${categoryPopoverHeading}`
                  : "Select a category to edit"}
              </div>
              <div style={{display:"flex", flexDirection:"row", alignItems:"center",}}>

              {transactionType === "Income" && (
                <>
                  {isEditButton !== false && (
                    <EditIcon
                      className="change-icon"
                      style={{position:"static", marginLeft:"15px",}}
                      onClick={handleEditCategory}
                    />
                  )}
                  <ChangeCategoryIcon
                    className="edit-icon"
                    style={{position:"static", marginLeft:"15px"}}
                    onClick={handleChangeCategory}
                  />
                </>
              )}
              {transactionType !== "Income" && (
                <>
                  {isEditButton !== false && (
                    <EditIcon
                      className="edit-icon"
                      style={{position:"static", marginLeft:"15px"}}
                      onClick={handleEditCategory}
                    />
                  )}
                </>
              )}

              <button
                onClick={() => togglePopover(transactionId)}
                className="category-close-button"
                style={{position:"static"}}
              >
                <p style={{ marginBottom:"0px" }}>
                  <Add_round_cross />
                </p>
              </button>
              </div>
              </div>
              <div className="category-subname">
                {categorySubTitle ||
                  `${
                    transactionType === "Income"
                      ? isIncomeCategoryChange
                        ? "Income"
                        : "Expense"
                      : transactionType
                  } Category`}
              </div>
              <div className="categories group-btn green-btn">
                <div className="categories group-btn green-btn ">
                  {categories &&
                    categories?.length > 0 &&
                    categories.map(
                      (it) =>
                        checkNotEditable(it?.type, isEdit) && (
                          <div
                            key={it?.userCategoryId}
                            className="button-blck"
                            onClick={() => handleCategoryClick(it)}
                            style={{ marginRight: "10px" }}
                          >
                            <div className={`icon-tag`}>
                              {it?.name !== "Uncategorised" && (
                                <div
                                  className={`icon-transaction-img ${
                                    isHexColor(it?.colour)
                                      ? ""
                                      : categoryBackgroundCss(it?.colour)
                                  }`}
                                  style={{
                                    backgroundColor: categoryColorForAll(
                                      it?.colour
                                    ),
                                  }}
                                >
                                  {it?.emoji}
                                </div>
                              )}
                              <div className="content">
                                <h6 className={`icon-content hello`}>
                                  {it?.name}
                                </h6>
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  {isNewCategoryPopup && !isEdit && (
                    <button
                      className={`new-category-btn green ${
                        true <= 0 ? "green" : ""
                      }`}
                      onClick={handleChange}
                    >
                      + New category
                    </button>
                  )}
                </div>
              </div>
              {oneOffCategory && !isEdit && oneOffCategory?.length > 0 && (
                <>
                  <div className="category-subname">One-off Category</div>
                  <div className="categories group-btn green-btn">
                    <div className="categories group-btn green-btn ">
                      {oneOffCategory &&
                        oneOffCategory?.length > 0 &&
                        oneOffCategory.map((it) => (
                          <div
                            key={it?.userCategoryId}
                            className="button-blck"
                            onClick={() => handleCategoryClick(it)}
                            style={{ marginRight: "10px" }}
                          >
                            <div className={`icon-tag`}>
                              {it?.name !== "Uncategorised" && (
                                <div
                                  className={`icon-transaction-img ${
                                    isHexColor(it?.colour)
                                      ? ""
                                      : categoryBackgroundCss(it?.colour)
                                  }`}
                                  style={{
                                    backgroundColor: categoryColorForAll(
                                      it?.colour
                                    ),
                                  }}
                                >
                                  {it?.emoji}
                                </div>
                              )}
                              <div className="content">
                                <h6 className={`icon-content hello`}>
                                  {it?.name}
                                </h6>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </PopoverBody>
        </Popover>
      )}
    </>
  );
};

export default ListingCategories;
