import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as WordLogoBlack } from "../../../assests/images/WordLogoBlack.svg";
import "../Login.css";
import { ReactComponent as Success } from "../../../assests/images/icons/passwordSucess.svg";
import { motion } from "framer-motion";
import HelpUsScreen from "./HelpUsScreen";

const VerifyEmail = () => {
  const [welcomeScreen, setWelcomeScreen] = useState(true);
  const userDataDetails = JSON.parse(localStorage.getItem("userData"));

  return (
    <>
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="logoCss text-center mb-4">
                <WordLogoBlack />
              </div>
              <motion.div
                className="loginFormWrp"
                style={{ borderRadius: "20px", padding: "30px" }}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.5 }}
              >
                {welcomeScreen && (
                  <div className="transaction-categories mt-2">
                    <div
                      className="success-img"
                      style={{ textAlign: "center" }}
                    >
                      <Success />
                    </div>
                    <p
                      className="bold popover-live"
                      style={{
                        textAlign: "center",
                        fontSize: "24px",
                        marginBottom: "5px",
                      }}
                    >
                      Welcome,{" "}
                      {userDataDetails.firstName.charAt(0).toUpperCase() +
                        userDataDetails.firstName.slice(1)}{" "}
                      👋🏻
                    </p>

                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        marginBottom: "10px",
                      }}
                    >
                      Let’s get started getting you set up!
                    </p>
                    <div className="modal-popup-btn-wrp">
                      <button
                        className="change-password-btn cursor-pointer"
                        type="button"
                        onClick={() => setWelcomeScreen(false)}
                        style={{
                          marginTop: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        Next step
                      </button>
                    </div>
                  </div>
                )}

                {!welcomeScreen && <HelpUsScreen />}
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default VerifyEmail;
