import React, { useEffect, useState, useRef, useCallback } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../AddIncome/addIncome.css";
import "../../ModalForm/ChangePassword/index.css";
import "./index.css";

import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CategoryTag from "../../Components/CategoryTag/index.jsx";
import weburls from "../../Weburls/weburls.jsx";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../assests/images/icons/trash-2.svg";
import { ReactComponent as CloseRuleIcon } from "../../assests/images/icons/delete-rule-icon.svg";

import {
  PopUpDelete,
  PopUpRuleUpdate,
} from "../../Components/BudgetDetails/PopUpForUpdateDelete.jsx";
import { toast } from "react-toastify";
import { Loading } from "../../Components/Loading/Loading.jsx";
import ReactDOM from "react-dom";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
});

const UpdateTransactionRuleModel = ({
  modalOpen,
  closeModal,
  expenseCategories,
  incomeCategories,
  data = {},
  fetchTransactionRule,
}) => {
  const { uuid } = useParams();
  const token = localStorage.getItem("budget-token");
  const [transactionType, setTransactionType] = useState(data.rule_type);
  const [selectedCategory, setSelectedCategory] = useState({
    id: data.user_category_id,
    emoji: data.emoji,
    name: data.name,
    colour: data.colour,
  });
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: data.rule_name,
    },
  });

  const onSubmit = (data) => {
    setFormData(data);
    setShowUpdatePopup(true);
  };

  useEffect(() => {
    if (!modalOpen) {
      reset({ name: "" });
    }
  }, [modalOpen, reset]);

  const onCategoryChange = (data) => {
    const { userCategoryId, emoji, colour, name } = data;
    setSelectedCategory({
      id: userCategoryId,
      colour: colour,
      emoji: emoji,
      name: name,
    });
  };

  const handleDeleteRules = () => {
    setShowDeletePopup(true);
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
  };

  const deleteTransactionRule = async () => {
    setLoading(true);
    try {
      const response = await axios.delete(
        `${weburls.Delete_Transaction_rule}${uuid}/${data.rule_uuid}`,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );

      if (response?.data.code === 1) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      if (response?.data.code === 0) {
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      fetchTransactionRule();
      setLoading(false);
    } catch (error) {
      toast.error("Somthing went wrong...");
    }
  };

  const handleDeleteConfirm = async () => {
    await deleteTransactionRule();
    closeModal("updateTransactionRules");
  };
  const updateTransactionRule = async (bodyData) => {
    setShowUpdatePopup(false);
    setLoading(true);
    try {
      const response = await axios.put(
        `${weburls.Update_Transaction_rule}${uuid}/${data.rule_uuid}`,
        bodyData,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );

      if (response?.data.code === 1) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      if (response?.data.code === 0) {
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      fetchTransactionRule();
      setLoading(false);
    } catch (error) {
      toast.error("Somthing went wrong...");
    }
  };
  const handleUdpateConfirm = async (confirmed) => {
    const bodyData = {
      ruleType: transactionType,
      ruleName: formData.name,
      userCategoryId: selectedCategory.id,
      isCategorized: confirmed,
    };
    await updateTransactionRule(bodyData);

    closeModal("updateTransactionRules");
  };

  return (
    <>
      {loading &&
        ReactDOM.createPortal(
          <div className="overlay-popup-loading">
            <Loading />
          </div>,
          document.body
        )}
      <div className={`modal-background ${modalOpen ? "open-modal" : ""}`}>
        <div className="modal-content add-modal-width add-modal-padding">
          <div className="close-pass-button" style={{ top: "25px" }}>
            <DeleteIcon onClick={handleDeleteRules} />
            <CloseRuleIcon
              onClick={() => closeModal("updateTransactionRules")}
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div className="fs-3">
                  <span className="bold black password-heading">
                    Edit transaction rule
                  </span>
                </div>
              </div>

              <div className="cash-account-box shadow mt-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="fw-bold">Transaction type</div>
                  <div className="account-input">
                    <div
                      className={`account-select cursor-pointer ${
                        transactionType === "Expense" && "account-select-income"
                      }`}
                      name="accountType"
                      onClick={() => {
                        setTransactionType(
                          transactionType === "Expense" ? "Income" : "Expense"
                        );
                        setSelectedCategory({
                          id: 0,
                          emoji: "",
                          name: "Select a category",
                          colour: "",
                        });
                      }}
                    >
                      <span className="d-flex justify-content-center">
                        {transactionType}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="fw-bold">IF the transaction</div>
                  <div className="account-input">
                    <div className="account-select cursor-pointer Expense">
                      <span className="d-flex justify-content-center">
                        Name
                      </span>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-3">
                  <div className="fw-bold">Equals</div>
                  <div className="d-flex flex-column">
                    <div className="account-input">
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            style={{ width: "266px" }}
                          />
                        )}
                      />
                    </div>
                    {errors.name && (
                      <span className="error-message mt-1">
                        {errors?.name?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <div className="fw-bold">Then categorise it to</div>
                  <div className="account-input">
                    <CategoryTag
                      emoji={selectedCategory.emoji}
                      name={selectedCategory.name}
                      colour={selectedCategory.colour}
                      expenseCategories={expenseCategories}
                      incomeCategories={incomeCategories}
                      amount={transactionType === "Expense" ? -1 : 1}
                      uniqueID={1}
                      updateCategory={onCategoryChange}
                      status={""}
                    />
                  </div>
                </div>
              </div>

              <div className="trans-udpade-rule-note mt-3 justify-content-between">
                <span className="trans-500">Note:</span> You can only edit the
                name or category of the rule. If you want to edit the
                transaction type, simply delete this rule and create a new one.
              </div>

              <button
                type="submit"
                className="add-transaction-rules-btn cursor-pointer mt-3"
              >
                Save changes
              </button>
            </div>
          </form>
        </div>
        {showDeletePopup && (
          <PopUpDelete
            handleDeleteCancel={handleDeleteCancel}
            handleDeleteConfirm={handleDeleteConfirm}
          />
        )}
        {showUpdatePopup && (
          <PopUpRuleUpdate handleChanges={handleUdpateConfirm} />
        )}
      </div>
    </>
  );
};

export default UpdateTransactionRuleModel;
