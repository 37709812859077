import { ReactComponent as Client } from "../../assests/images/icons/client.svg";
import { ReactComponent as Doller } from "../../assests/images/icons/cashflowIcon.svg";
import { ReactComponent as RetirementIcon } from "../../assests/images/icons/RetirementIcon.svg";
import { ReactComponent as TransactionIcon } from "../../assests/images/icons/transactionIcon.svg";
import { ReactComponent as Accounts } from "../../assests/images/icons/accountsIcon.svg";
import { ReactComponent as ConnectAccountIcon } from "../../assests/images/icons/connectAccountIcon.svg";
import { ReactComponent as Home } from "../../assests/images/icons/home.svg";
import { ReactComponent as Setting } from "../../assests/images/icons/setting.svg";
import { ReactComponent as Shape } from "../../assests/images/icons/budgetIcon.svg";
import { ReactComponent as Trophy } from "../../assests/images/icons/goalsIcon.svg";
import { ReactComponent as Wallet } from "../../assests/images/icons/networthIcon.svg";
import { ReactComponent as MoneyInsights } from "../../assests/images/icons/moneyInsightsSideBarIcon.svg";
// bank connections
import { ReactComponent as TSB } from "../../assests/images/ConnectAccounts/TSB.svg";
import { ReactComponent as BNZ } from "../../assests/images/ConnectAccounts/BNZ.svg";
import { ReactComponent as ANZ } from "../../assests/images/ConnectAccounts/ANZ.svg";
import { ReactComponent as ASB } from "../../assests/images/ConnectAccounts/ASB.svg";
import { ReactComponent as KIWI } from "../../assests/images/ConnectAccounts/Kiwibank.svg";
import { ReactComponent as WESTPAC } from "../../assests/images/ConnectAccounts/Westpac.svg";
import { ReactComponent as COOP } from "../../assests/images/ConnectAccounts/Coop.svg";
import { ReactComponent as RABO } from "../../assests/images/ConnectAccounts/Rabobank.svg";
import { ReactComponent as WISE } from "../../assests/images/ConnectAccounts/Wise (1).svg";
import { ReactComponent as HEARTLAND } from "../../assests/images/ConnectAccounts/Heartland.svg";
import { ReactComponent as NZHL } from "../../assests/images/ConnectAccounts/NZHL.svg";
import { ReactComponent as SBS } from "../../assests/images/ConnectAccounts/SBS.svg";
// add kiwisaver
import { ReactComponent as Pie } from "../../assests/images/ConnectAccounts/Pie (1).svg";
import { ReactComponent as Fisher } from "../../assests/images/ConnectAccounts/Fisher.svg";
import { ReactComponent as Super } from "../../assests/images/ConnectAccounts/Superlife.svg";
import { ReactComponent as Milford } from "../../assests/images/ConnectAccounts//Milford.svg";
import { ReactComponent as Generate } from "../../assests/images/ConnectAccounts/Generate.svg";
import { ReactComponent as Kernal } from "../../assests/images/ConnectAccounts/Kernel.svg";
import { ReactComponent as KiwiWealth } from "../../assests/images/ConnectAccounts/Kiwiwealth.svg";
import { ReactComponent as Juno } from "../../assests/images/ConnectAccounts/Juno.svg";
import { ReactComponent as Booster } from "../../assests/images/ConnectAccounts/Booster.svg";
import { ReactComponent as Simplicity } from "../../assests/images/ConnectAccounts/Simplicity.svg";
// add investment
import { ReactComponent as Elipses19 } from "../../assests/images/ConnectAccounts/Ellipse 19.svg";
import { ReactComponent as Elipses20 } from "../../assests/images/ConnectAccounts/Ellipse 20.svg";
import { ReactComponent as Elipses21 } from "../../assests/images/ConnectAccounts/Ellipse 21.svg";
import { ReactComponent as Elipses22 } from "../../assests/images/ConnectAccounts/Ellipse 22.svg";
import { ReactComponent as Other1 } from "../../assests/images/ConnectAccounts/other1.svg";
import { ReactComponent as Other2 } from "../../assests/images/ConnectAccounts/other2.svg";
import { ReactComponent as Other3 } from "../../assests/images/ConnectAccounts/other3.svg";
import { colorToExtraLightenColor } from "../helper";

export const menuItems = (uuid) => [
  // { path: `/dashboard/${uuid}`, icon: Home, text: "Dashboard" },
  { path: `/budget/${uuid}`, icon: Shape, text: "Budget" },
  { path: `/merchants/${uuid}`, icon: Client, text: "Merchants" },
  // {
  //   path: `/moneyinsights/${uuid}`,
  //   icon: MoneyInsights,
  //   text: "Money Insights",
  // },
  // { path: `/goals/${uuid}`, icon: Trophy, text: "Goals" },
  // { path: `/cashflow/${uuid}`, icon: Doller, text: "Cashflow" },
  // { path: `/retirement/${uuid}`, icon: RetirementIcon, text: "Retirement" },
  // { path: `/networth/${uuid}`, icon: Wallet, text: "Net Worth" },
  {
    path: `/transactions/${uuid}`,
    icon: TransactionIcon,
    text: "Transactions",
  },
  { path: `/accounts/${uuid}`, icon: Accounts, text: "Accounts" },
  {
    path: `/connectaccounts/${uuid}`,
    icon: ConnectAccountIcon,
    text: "Connect\u00A0accounts",
  },
  { path: `/settings/${uuid}`, icon: Setting, text: "Settings" },
];

export const iconCategories = {
  bankConnections: [
    <TSB />,
    <BNZ />,
    <ANZ />,
    <ASB />,
    <KIWI />,
    <WESTPAC />,
    <COOP />,
    <RABO />,
    <WISE />,
    <HEARTLAND />,
    <NZHL />,
    <SBS />,
  ],
  kiwiSaverConnections: [
    <Pie />,
    <Fisher />,
    <Super />,
    <Milford />,
    <Generate />,
    <Kernal />,
    <KiwiWealth />,
    // <Juno />,
    <Booster />,
    <Simplicity />,
  ],
  addInvestmentConnections: [
    <Elipses19 />,
    <Elipses20 />,
    <Elipses21 />,
    <Elipses22 />,
  ],
  otherConnections: [<Other1 />, <Other2 />, <Other3 />],
};

export const howOftenData = [
  { id: "Weekly", title: "Weekly" },
  { id: "Fortnightly", title: "Fortnightly" },
  { id: "Monthly", title: "Monthly" },
];

export const dateOptions = [
  "This week",
  "This month",
  "This quarter",
  "Last week",
  "Last month",
  "Last quarter",
];
export const merchantCategoriesOpts = ["All", "Categorised", "Un-categorised"];

export const mertchantSortOpts = [
  "Name A-Z",
  "Spend highest to lowest",
  "Spend lowest to highest",
];

export const emojiBackgroundColor = (color) => {
  switch (color) {
    case "Dark green":
      return "dark-green-color";
    case "Red":
      return "emoji-backround-red";
    case "Yellow":
      return "emoji-backround-yellow";
    case "Blue":
      return "emoji-backround-blue";
    case "Purple":
      return "emoji-backround-purple";
    case "Orange":
      return "emoji-backround-orange";
    case "Pink":
      return "emoji-backround-pink";
    case "Light blue":
      return "light-blue-color";
    case "Lime green":
      return "lime-green-color";
    case "Dark red":
      return "dark-red-color";
    case "Green":
      return "emoji-backround-green";
    case "Dark blue":
      return "dark-blue-color";
    default:
      return "green";
  }
};

export const predefinedColors = [
  "Red",
  "Light green",
  "Blue",
  "Green",
  "Light blue",
  "Orange",
  "Yellow",
  "Purple",
  "Pink",
];

export const colorHexMap = {
  Red: "#E0533D",
  Yellow: "#FFEC48",
  Blue: "#3D6BE0",
  Purple: "#983DE0",
  Orange: "#E09F3D",
  Pink: "#DD3DE0",
  "Light blue": "#3DAFE0",
  "Light green": "#3DE0B9",
  Green: "#3DE06B",
};

export const getDisplayOption = (option) => {
  const periodMapping = {
    thisWeek: "This week",
    lastWeek: "Last week",
    quarter: "This quarter",
    lastQuarter: "Last quarter",
    monthly: "This month",
    lastMonth: "Last month",
    thisYear: "This year",
    lastFortnightly: "Last fortnight",
    thisFortnightly: "This fortnight",
    thisCustom: "Custom",
    lastCustom: "Custom - last period",
  };

  return periodMapping[option] || option;
};

export const hexToColorMap = {
  "#E0533D": "Red",
  "#FFEC48": "Yellow",
  "#3D6BE0": "Blue",
  "#983DE0": "Purple",
  "#E09F3D": "Orange",
  "#DD3DE0": "Pink",
  "#3DAFE0": "Light blue",
  "#3DE0B9": "Light green",
  "#3DE06B": "Green",
};

export const howOftencolorCss = (color) => {
  switch (color) {
    case "Weekly":
      return "#479985";
    case "Fortnightly":
      return "#3de1bc";
    case "Monthly":
      return "#3d6ae2";
    case "Quarterly":
      return "#3eb1e1";
    case "Annual":
      return "#39e16f";
    case "Custom":
      return "#8D83FE";
    default:
      return "#479985";
  }
};

export const iconCategoriesWithHeadings = {
  bankConnections: [
    { icon: <TSB style={{ height: "40px", width: "40px", textAlign:"" }} />, heading: "TSB" },
    { icon: <BNZ style={{ height: "40px", width: "40px", textAlign:"" }} />, heading: "BNZ" },
    { icon: <ANZ style={{ height: "40px", width: "40px", textAlign:"" }} />, heading: "ANZ" },
    { icon: <ASB style={{ height: "40px", width: "40px", textAlign:"" }} />, heading: "ASB" },
    { icon: <KIWI style={{ height: "40px", width: "40px", textAlign:"" }} />, heading: "KIWI" },
    {
      icon: <WESTPAC style={{ height: "40px", width: "40px", textAlign:"" }} />,
      heading: "WESTPAC",
    },
    { icon: <COOP style={{ height: "40px", width: "40px", textAlign:"" }} />, heading: "COOP" },
    { icon: <RABO style={{ height: "40px", width: "40px", textAlign:"" }} />, heading: "RABO" },
    { icon: <WISE style={{ height: "40px", width: "40px", textAlign:"" }} />, heading: "WISE" },
    {
      icon: <HEARTLAND style={{ height: "40px", width: "40px", textAlign:"" }} />,
      heading: "HEARTLAND",
    },
    { icon: <NZHL style={{ height: "40px", width: "40px", textAlign:"" }} />, heading: "NZHL" },
    { icon: <SBS style={{ height: "40px", width: "40px", textAlign:"" }} />, heading: "SBS" },
  ],
  kiwiSaverConnections: [
    { icon: <Pie style={{ height: "40px", width: "40px", textAlign:"" }} />, heading: "Pie" },
    { icon: <Fisher style={{ height: "40px", width: "40px", textAlign:"" }} />, heading: "Fisher" },
    { icon: <Super style={{ height: "40px", width: "40px", textAlign:"" }} />, heading: "Super" },
    {
      icon: <Milford style={{ height: "40px", width: "40px", textAlign:"" }} />,
      heading: "Milford",
    },
    {
      icon: <Generate style={{ height: "40px", width: "40px", textAlign:"" }} />,
      heading: "Generate",
    },
    { icon: <Kernal style={{ height: "40px", width: "40px", textAlign:"" }} />, heading: "Kernal" },
    {
      icon: <KiwiWealth style={{ height: "40px", width: "40px", textAlign:"" }} />,
      heading: "KiwiWealth",
    },
    // {icon:<Juno style={{height:"40px", width:"40px"}} />, heading:""},
    {
      icon: <Booster style={{ height: "40px", width: "40px", textAlign:"" }} />,
      heading: "Booster",
    },
    {
      icon: <Simplicity style={{ height: "40px", width: "40px", textAlign:"" }} />,
      heading: "Simplicity",
    },
  ],
  addInvestmentConnections: [
    {
      icon: <Elipses19 style={{ height: "40px", width: "40px", textAlign:"" }} />,
      heading: "Sharesies",
    },
    {
      icon: <Elipses20 style={{ height: "40px", width: "40px", textAlign:"" }} />,
      heading: "Hatch",
    },
    {
      icon: <Elipses21 style={{ height: "40px", width: "40px", textAlign:"" }} />,
      heading: "STAKE",
    },
    {
      icon: <Elipses22 style={{ height: "40px", width: "40px", textAlign:"" }} />,
      heading: "Sharesight",
    },
  ],
  otherConnections: [
    { icon: <Other1 style={{ height: "40px", width: "40px", textAlign:"" }} />, heading: "Gem" },
    { icon: <Other2 style={{ height: "40px", width: "40px", textAlign:"" }} />, heading: "American Express" },
    { icon: <Other3 style={{ height: "40px", width: "40px", textAlign:"" }} />, heading: "Inland" },
  ],
};

export const progressbarColor = (color) => {
  if (!color) return "";
  if (color?.startsWith("#")) {
    return color;
  }

  switch (color) {
    case "Red":
      return "#FF0000";
    case "Yellow":
      return "#FFEC48";
    case "Blue":
      return "#1400FF";
    case "Purple":
      return "#AD00FF";
    case "Orange":
      return "#FF9900";
    case "Pink":
      return "#EB00FF";
    case "Light blue":
      return "#00AAFF";
    case "Light green":
      return "#00FFD1";
    case "Green":
      return "#10E325";
    default:
      return "";
  }
};

const getHexCodeByColor = (color) => {
  switch (color) {
    case "Red":
      return "#E0533D";
    case "Light green":
      return "#3DE0B9";
    case "Blue":
      return "#3D6BE0";
    case "Green":
      return "#3DE06B";
    case "Light blue":
      return "#3DAFE0";
    case "Orange":
      return "#E09F3D";
    case "Yellow":
      return "#DDE03D";
    case "Purple":
      return "#983DE0";
    case "Pink":
      return "#DD3DE0";
    default:
      return "";
  }
};

export const lightBackgroundColor = (colorOrHex) => {
  if (colorOrHex?.startsWith("#")) {
    return colorToExtraLightenColor(colorOrHex);
  }

  const hexCode = selectionColor(colorOrHex);
  return colorToExtraLightenColor(hexCode);
};

export const colorCss = (color) => {
  switch (color) {
    case "Red":
      return "red-color";
    case "Light green":
      return "light-green-color";
    case "Blue":
      return "blue-color";
    case "Green":
      return "green-color";
    case "Light blue":
      return "light-blue-color";
    case "Orange":
      return "orange-color";
    case "Yellow":
      return "yellow-color";
    case "Purple":
      return "purple-color";
    case "Pink":
      return "pink-color";
    default:
      return "";
  }
};

export const categoryBackgroundCss = (color) => {
  switch (color) {
    case "Dark green":
      return "light-dark-green-color";
    case "Red":
      return "light-category-red";
    case "Yellow":
      return "light-category-yellow";
    case "Blue":
      return "light-category-blue";
    case "Purple":
      return "light-category-purple";
    case "Orange":
      return "light-category-orange";
    case "Pink":
      return "light-category-pink";
    case "Light blue":
      return "light-category-lightblue";
    case "Light green":
      return "light-category-lightgreen";
    case "Dark red":
      return "light-category-red";
    case "Green":
      return "light-category-green";
    case "Dark blue":
      return "light-category-red";
    default:
      return "light-category-default";
  }
};

export const customColourOrName = (colour) => {
  return getCustomCodeToName(colour)?.startsWith("#")
    ? "Custom"
    : getCustomCodeToName(colour);
};

export const customColourOrNameColour = (colour) =>{  
  return getCustomCodeToName(colour)?.startsWith("#")
      ? colour
      : getHexCodeByColor(getCustomCodeToName(colour)) ?? "#E0533D";
}

export const getCustomCodeToName = (colorCode) => {
  switch (colorCode) {
    case "#EFCCC6":
    case "Red":
    case "#FF0000":
      return "Red";

    case "#C6EFE5":
    case "Light green":
    case "#00FFD1":
      return "Light green";

    case "#C6D2EF":
    case "Blue":
    case "#1400FF":
      return "Blue";

    case "#C6EFD2":
    case "Green":
    case "#10E325":
      return "Green";

    case "#C6E3EF":
    case "Light blue":
    case "#00AAFF":
      return "Light blue";

    case "#EFDFC6":
    case "Orange":
    case "#FF9900":
      return "Orange";

    case "#EEEFC6":
    case "Yellow":
    case "#FFEC48":
      return "Yellow";

    case "#DEC6EF":
    case "Purple":
    case "#AD00FF":
      return "Purple";

    case "#EEC6EF":
    case "Pink":
    case "#EB00FF":
      return "Pink";

    default:
      return colorCode;
  }
};

export const pickColorCode = (color) => {
  switch (color) {
    case "Red":
      return "#EFCCC6";
    case "Light green":
      return "#C6EFE5";
    case "Blue":
      return "#C6D2EF";
    case "Green":
      return "#C6EFD2";
    case "Light blue":
      return "#C6E3EF";
    case "Orange":
      return "#EFDFC6";
    case "Yellow":
      return "#EEEFC6";
    case "Purple":
      return "#DEC6EF";
    case "Pink":
      return "#EEC6EF";
    default:
      return color;
  }
};

export const categoryColorForAll = (colour) => {
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);
  if (colour == "#C6C8EF" || colour == "#EFC6C6" || colour == "#C6EFD1") {
    // Tranfer // Ignore // One off
    return colour;
  }
  
  if (isHexColor(colour)) {
    const name = getCustomCodeToName(colour);
    if (name == colour) {
      return colorToExtraLightenColor(colour);
    } else {
      return colour;
    }
  } else {
    const colorCode = pickColorCode(colour);
    return colorCode;
  }
};

export const selectionColor = (color) => {
  switch (color) {
    case "Red":
      return "#E0533D";
    case "Light green":
      return "#3DE0B9";
    case "Blue":
      return "#3D6BE0";
    case "Green":
      return "#3DE06B";
    case "Light blue":
      return "#3DAFE0";
    case "Orange":
      return "#E09F3D";
    case "Yellow":
      return "#DDE03D";
    case "Purple":
      return "#983DE0";
    case "Pink":
      return "#DD3DE0";
    default:
      return color;
  }
};

export const moduleInfoPopUpData = {
  merchants: {
    title: "Merchants 🧾",
    description: [
      "Your merchants are every place where you spend your money. Instead of having to categorise multiple transactions for the same merchant, you can group them all here.",
      "New merchants are added automatically, you'll need to categorise them into an expense category you've created in your budget",
      "You can filter and sort merchants by tapping the = icon. If you want to add a new merchant manually, you can tap the + icon.",
    ],
  },
  connectAccounts: {
    title: "Connect your accounts 🔗",
    description: [
      "Connect to all your banks, Kiwisaver, and share portfolios.",
      "Tap on your bank, Kiwisaver, or share portfolio logo to initiate the connection. You'll then be taken to Akahu's website to set up and confirm the connection.",
      "You can re-sync connections by tapping the re-sync connections' button at the bottom",
      "Remove any connections by either clicking the cross under live connections or by clicking 'manage my connection'.",
    ],
  },
  accounts: {
    title: "Accounts 💵",
    description: [
      "View all your bank, Kiwisaver, and share portfolio account balances in one place.",
      "Click the account widget to copy the account number.Add a new account by entering in the details on the right hand side.",
      "If you'd like to connect a new account, go to the Connect Your Accounts screen",
      "If you have cash laying around, create a cash account by tapping the button down at the bottom or entering in the details on the right as well.",
    ],
  },
  netWorth: {
    title: "Net Worth 💰",
    description: [
      "Your net worth is your assets (what you own) minus your liabilities (what you owe).",
      "Assets can be physical things, like a house you own. A liability is typically money you owe someone, such as a bank.",
      "You can enter all the assets you own and the value of each asset. You can then do the same for your liabilities and enter in what's owed.",
      "You can see a full summary and track your progress using the widgets at the top. ",
    ],
  },
  goals: {
    title: "Goals 🎯",
    description: [
      "Your goals are where you can set and track any financial milestones you'd like to achieve",
      "This can be big or small. You can customise goals to exactly what you want.",
      "Each goal widget predicts when you're expected to achieve the goal and shows you how on track you are.Add a new goal by tapping 'Add a new goal' button.",
      "You can also view and edit the details of each goal by tapping 'See detail'.",
    ],
  },
};
