import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as WordLogoBlack } from "../../../assests/images/WordLogoBlack.svg";
import { ReactComponent as Akahu } from "../../../assests/images/icons/akahuIcon.svg";
import "../Login.css";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const ConnectAkahuVerifyEmail = () => {
  const akahuToken = process.env.REACT_APP_AKAHU_APP_TOKEN;
  const akahuCallbackUrl = process.env.REACT_APP_AKAHU_CALLBACK_URL;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();

  const redirectToAkahuPortal = () => {
    const url = `https://oauth.akahu.io/?client_id=${akahuToken}&response_type=code&redirect_uri=${akahuCallbackUrl}&scope=ENDURING_CONSENT&email=${userData.username}&state=htfkf`;
    window.location.href = url;
  };

  return (
    <>
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="logoCss text-center mb-4">
                <WordLogoBlack />
              </div>
              <motion.div
                className="loginFormWrp"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.5 }}
                style={{ padding: "0px" }}
              >
                <div
                  className=""
                  style={{
                    marginTop: "0px",
                    padding: "40px",
                    background: "#D8E8ED",
                    borderRadius: "20px",
                  }}
                >
                  <div className="d-flex align-items-center mb-3 akahu-modal-title">
                    <div className="bank-connection-img">
                      <Akahu />
                    </div>
                    <div className="bold black who-akahu">
                      Connect using Akahu
                    </div>
                  </div>
                  <div className="setting_text_color mt-2">
                    Akahu is New Zealand’s open finance provider.
                  </div>
                  <div className="setting_text_color mt-4">
                    Akahu makes it simple to securely access your financial
                    information and to provide that data with platforms like
                    BudgetBuddie.
                  </div>
                  <div className="setting_text_color mt-3">
                    Akahu uses 256-bit bank-grade encryption to keep your data
                    safe and secure.
                  </div>
                  <div className="setting_text_color mt-3">
                    With your consent, the secure connection with Akahu will
                    pull through your bank balances, transactional information,
                    and account details.
                  </div>
                  <div className="setting_text_color mt-3">
                    You’ll be redirected to Akahu’s website to establish the
                    connection.
                  </div>
                  <div className="setting_text_color mt-3">
                    To connect your accounts, tap the below button.{" "}
                  </div>
                  <div
                    className="flex-content-center"
                    onClick={redirectToAkahuPortal}
                  >
                    <div className="inves-btn">Connect my accounts</div>
                  </div>
                </div>
              </motion.div>
              <div className="d-flex flex-row align-items-center gap-1 mt-3">
                <p
                  style={{
                    fontSize: "14px",
                    color: "#B7B8C1",
                    fontWeight: "500",
                    margin:"0px"
                  }}
                >
                  Missed something?
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#469B88",
                    fontWeight: "700",
                    cursor:"pointer",
                    margin:"0px"
                  }}
                  onClick={() => navigate(-1)} 
                >
                  
                  Go back a step
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
       
    </>
  );
};
export default ConnectAkahuVerifyEmail;
