import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import weburls from "../../Weburls/weburls";
import axios from "axios";
import EmailOtp from "../../assests/images/email-gif.gif";
import OtpBox from "./OtpBox";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";

const VerificationEmail = () => {
  const [emailOtp, setEmailOtp] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let tokenUser = localStorage.getItem("budget-token");
  const userDataDetails = JSON.parse(localStorage.getItem("userData"));
  const roleId = JSON.parse(localStorage.getItem("roleId"));

  const handleOtpSubmit = (otp) => {
    setEmailOtp(otp);
  };

  const submitOtp = async () => {
    setLoading(true);
    if (emailOtp.length !== 6) {
      return toast.error("Please enter 6 digit code", {
        position: "top-right",
        autoClose: 2000,
      });
    }
    const data = {
      userUuid: userDataDetails?.userUuid,
      otp: emailOtp,
    };
    try {
      const response = await axios.post(
        weburls.Enable_2Factor_Authentication,
        data,
        {
          headers: {
            authorization: `Basic ${tokenUser}`,
          },
        }
      );
      if (response.data.code === 0) {
        toast.error("Incorrect code", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (response.data.code === 1) {
        localStorage.setItem("2fa-done", true);
        const userRole = roleId || 5;

        switch (userRole) {
          case 1:
            navigate("/SuperAdmin");
            break;
          case 5:
            navigate(`/budget/${userDataDetails?.userUuid}`, { replace: true });
            break;
          default:
            navigate(`/budget/${userDataDetails?.userUuid}`, { replace: true });
            break;
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <section className="login-section loginSectionWelcome">
      <div
        className={`modal-background open-modal `}
        style={{ background: "none" }}
      >
        <div className="modal-content delete-modal-width">
          <>
            <div className="sucess-container transition-delay">
              <div className="success-img">
                <div className="text-center">
                  <img src={EmailOtp} className="email-gif" alt="Email OTP" />
                </div>
              </div>
              <div className="otp-modal-title">
                <span
                  className="password-heading"
                  style={{ lineHeight: "normal" }}
                >
                  We have sent a verification code to your email
                </span>
              </div>
              <p className="sucess-p">
                Check your email and enter the code below to authenticate it’s
                you.
              </p>
              <OtpBox length={6} onSubmit={handleOtpSubmit} />

              <div class="modal-popup-btn-wrp">
                <button
                  className={` ${
                    loading ? "forgot-btn-load" : "change-password-btn"
                  }`}
                  type="button"
                  onClick={submitOtp}
                  disabled={loading}
                >
                  {loading ? (
                    <ClipLoader size={15} color={"#FFFFFF"} />
                  ) : (
                    "Submit verification code"
                  )}
                </button>
              </div>
            </div>
          </>
        </div>
      </div>
    </section>
  );
};

export default VerificationEmail;
