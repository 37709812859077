import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { ReactComponent as CrossIcon } from "../../../assests/images/icons/cross-icon.svg";
import { ReactComponent as UnselectedCheck } from "../../../assests/images/icons/unselected-check.svg";
import { ReactComponent as SelectedCheck } from "../../../assests/images/icons/selected-check.svg";
import "./feedback_popUp.css";
import SelectFeaturePopUp from "./SelectFeaturePopUp";
import FeatureTextareaPopUp from "./FeatureTextareaPopUp";

const SelectTypeFeedback = ({
  isOpenMainModal,
  onCloseMainModal,
  setIsModalTypeFeedbackOpen,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [currentPopup, setCurrentPopup] = useState("main");
  const popoverRef = useRef(null);

  const options = [
    { id: "feature-request", label: "Feature request" },
    { id: "feature-feedback", label: "Feature feedback" },
    { id: "report-bug", label: "Report a bug" },
    { id: "general-feedback", label: "General feedback" },
  ];

  const handleOptionClick = (id) => {
    setSelectedOption(id);
  };

  const handleNextClick = () => {
    if (selectedOption === "feature-request") {
      setCurrentPopup("feature-request");
    } else if (
      selectedOption === "feature-feedback" ||
      selectedOption === "report-bug"
    ) {
      setCurrentPopup("feature-feedback");
    } else if (selectedOption === "general-feedback") {
      setCurrentPopup("general-feedback");
    }
  };

  const handleClosePopup = () => {
    setCurrentPopup("main");
    setIsModalTypeFeedbackOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRef.current &&
        (!popoverRef.current.contains(event.target) ||
          event.target === popoverRef.current)
      ) {
        onCloseMainModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCloseMainModal]);

  return (
    <>
      {currentPopup === "main" && (
        <div
          className="modal-overlay"
          isOpen={isOpenMainModal}
          toggle={onCloseMainModal}
          ref={popoverRef}
        >
          <motion.div
            className="modal-container"
            style={{
              padding: "20px",
              paddingTop: "10px",
              paddingBottom: "10px",
              borderRadius: "20px",
              maxWidth: "450px",
            }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.5 }}
          >
            <div className="transaction-categories mt-2">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="bold popover-live" style={{ fontSize: "24px" }}>
                  Select type
                </div>
                {/* <button
                  onClick={onCloseMainModal}
                  className="close-edit-category-button"
                  style={{
                    top: "0px",
                    position: "static",
                    cursor: "pointer",
                  }}
                >
                  <CrossIcon />
                </button> */}
              </div>
              <p style={{ fontSize: "14px", marginBottom: "10px" }}>
                Select if your feedback is a feature request, feature feedback,
                or just general feedback.
              </p>
              <div className="feedback-options">
                {options.map((option) => (
                  <div
                    key={option.id}
                    className="feedback-option"
                    onClick={() => handleOptionClick(option.id)}
                    style={{
                      border: `2px solid ${
                        selectedOption === option.id ? "#14B8A6" : "#E4E4E7"
                      }`,
                    }}
                  >
                    <p
                      className=""
                      style={{
                        margin: 0,
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#18181B",
                      }}
                    >
                      {option.label}
                    </p>
                    {selectedOption === option.id ? (
                      <SelectedCheck />
                    ) : (
                      <UnselectedCheck />
                    )}
                  </div>
                ))}
              </div>
              <div className="modal-popup-btn-wrp">
                <button
                  className="change-password-btn cursor-pointer"
                  type="button"
                  onClick={handleNextClick}
                  style={{
                    marginTop: "40px",
                    marginBottom: "10px",
                  }}
                  disabled={!selectedOption}
                >
                  Next
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      )}

      {currentPopup === "feature-request" && (
        <FeatureTextareaPopUp
          desc={
            "Provide your ideas and details on what feature(s) you’d like to be included."
          }
          heading={"Feature request"}
          onClose={handleClosePopup}
          setIsModalTypeFeedbackOpen={setIsModalTypeFeedbackOpen}
          feedbackType={selectedOption}
          isLastStep={true}
        />
      )}

      {currentPopup === "feature-feedback" && (
        <SelectFeaturePopUp
          desc={"Select which features you’d like to provide feedback on."}
          heading={"Select feature"}
          onClose={handleClosePopup}
          setIsModalTypeFeedbackOpen={setIsModalTypeFeedbackOpen}
          selectedOption={selectedOption}
        />
      )}

      {currentPopup === "general-feedback" && (
        <FeatureTextareaPopUp
          desc={
            "Provide any general feedback below about the app or BudgetBuddie as a whole."
          }
          heading={"General feedback"}
          onClose={handleClosePopup}
          setIsModalTypeFeedbackOpen={setIsModalTypeFeedbackOpen}
          feedbackType={selectedOption}
          isLastStep={true}
        />
      )}
    </>
  );
};

export default SelectTypeFeedback;