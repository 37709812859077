import React, { useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";
import "../../ModalForm/AddIncome/addIncome.css";
import { ReactComponent as Akahu } from "../../assests/images/icons/akahuIcon.svg";

const DeleteAccount = ({ modalOpen, closeModal, plan, setWhoopsPopUp }) => {
  const modalRef = useRef(null);
  const akahuToken = process.env.REACT_APP_AKAHU_APP_TOKEN;
  const akahuCallbackUrl = process.env.REACT_APP_AKAHU_CALLBACK_URL;
  const userData = JSON.parse(localStorage.getItem("userData"));

  const redirectToAkahuPortal = () => {
    if (plan === "Freemium") {
      setWhoopsPopUp(true);
    } else {
      const url = `https://oauth.akahu.io/?client_id=${akahuToken}&response_type=code&redirect_uri=${akahuCallbackUrl}&scope=ENDURING_CONSENT&email=${userData.username}&state=htfkf`;
      window.location.href = url;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (modalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalOpen, closeModal]);

  return (
    <div className={`modal-background ${modalOpen ? "open-modal" : ""}`}>
      <ToastContainer position="top-right" autoClose={5000} closeOnClick />
      <div
        ref={modalRef}
        className="modal-content delete-modal-width"
      >
        <button onClick={closeModal} className="close-pass-button">
          <i className="fa fa-times"></i>
        </button>
        <div className="">
          <div className="akahu-box" style={{ marginTop: "0px" }}>
            <div className="d-flex align-items-center mb-3 akahu-modal-title">
              <div className="bank-connection-img">
                <Akahu />
              </div>
              <div className="bold black who-akahu">Connect using Akahu</div>
            </div>
            <div className="setting_text_color mt-2">
              Akahu is New Zealand’s open finance provider.
            </div>
            <div className="setting_text_color mt-4">
              Akahu makes it simple to securely access your financial
              information and to provide that data with platforms like
              BudgetBuddie.
            </div>
            <div className="setting_text_color mt-3">
              Akahu uses 256-bit bank-grade encryption to keep your data safe
              and secure.
            </div>
            <div className="setting_text_color mt-3">
              With your consent, the secure connection with Akahu will pull
              through your bank balances, transactional information, and account
              details.
            </div>
            <div className="setting_text_color mt-3">
              You’ll be redirected to Akahu’s website to establish the
              connection.
            </div>
            <div className="setting_text_color mt-3">
              To connect your accounts, tap the below button.
            </div>
            <div
              className="flex-content-center"
              onClick={redirectToAkahuPortal}
            >
              <div className="inves-btn">Connect my accounts</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
