import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../src/assests/bootstrap/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./assests/css/style.css";
import AdminLayout from "./Layout/AdminLayout.jsx";
import Login from "./Components/pages/Login.jsx";
import ForgotPassword from "./Components/pages/ForgotPassword.jsx";
import VerifyResetPassword from "./Components/pages/VerifyResetPassword.jsx";
import SetNewPassword from "./Components/pages/SetNewPassword.jsx";
import VerificationEmail from "./Components/pages/VerificationEmail.jsx";
import VerifyEmail from "./Components/pages/verify-email/VerifyEmail.jsx";
import ConnectAkahuVerifyEmail from "./Components/pages/verify-email/ConnectAkahuVerifyEmail.jsx";
import AllConnected from "./Components/pages/verify-email/AllConnected.jsx";
import ConnectYourAccounts from "./Components/pages/verify-email/ConnectYourAccounts.jsx";
import AllSetUp from "./Components/pages/verify-email/AllSetUp.jsx";
import AkahuConnection from "./Components/pages/verify-email/AkahuConnection.jsx";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/verify-reset-password" element={<VerifyResetPassword />}></Route>
          <Route path="/set-new-password" element={<SetNewPassword />}></Route>
          <Route path="/2fa-verification" element={<VerificationEmail />}></Route>
          <Route path="/verify-email" element={<VerifyEmail />}></Route>
          <Route path="/connect-your-accounts" element={<ConnectYourAccounts />}></Route>
          <Route path="/connect-akahu-verify-email" element={<ConnectAkahuVerifyEmail />}></Route>
          <Route path="/akahu" element={<AkahuConnection />}></Route>
          <Route path="/all-connected" element={<AllConnected />}></Route>
          <Route path="/all-set-up" element={<AllSetUp />}></Route>
          <Route path="*" element={<AdminLayout />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;

