import React from "react";

const ConfirmationModal = ({
  isOpen,
  onCancel,
  onConfirm,
  textDesc,
  buttonText1,
  buttonText2,
  textDesc1,
  isLoading = false,
}) => {
  return (
    isOpen && (
      <div className="modal-background open-modal">
        <div
          className="modal-content confirm-modal-width"
          style={{ padding: "20px 0px" }}
        >
          <div>
            <div className="fs-3 revoke-title-flex">
              <span className="bold black popup-top-heading">{textDesc1}</span>
              <span className="revoke-desc">{textDesc}</span>
            </div>
          </div>
          <button className="revoke-green-btn" onClick={onCancel}>
            {buttonText1}
          </button>
          <button className="revoke-red-btn" onClick={onConfirm}>
            {isLoading ? (
              <div class="spinner-border text-light mt-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              buttonText2
            )}
          </button>
        </div>
      </div>
    )
  );
};

export default ConfirmationModal;
