import React from "react";

export const BankConnectionAccounts = ({
  connections,
  onIconClick,
  mainCss,
}) => {
  return (
    <div className={`${mainCss}`} onClick={onIconClick}>
      {connections?.map((data, index) => (
        <div
          key={index}
          className="d-flex justify-content-center"
          style={{ width: "40%", marginLeft: "10px" }}
        >
          <div
            className="bank-connection-sign-up"
            style={{ padding: "", textAlign: "center" }}
            // onClick={onIconClick}
          >
            <div>{data.icon} </div>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "500",
                marginBottom: "0px",
                justifyContent: "center",
                display: "flex",
              }}
            >
              {data.heading}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
