import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as WordLogoBlack } from "../../../assests/images/WordLogoBlack.svg";
import { ReactComponent as Akahu } from "../../../assests/images/icons/akahuIcon.svg";
import "../Login.css";
import { motion } from "framer-motion";
import {
  iconCategories,
  iconCategoriesWithHeadings,
} from "../../../utilities/Statics/index.jsx";
import { BankConnectionAccounts } from "./BankConnectionAccounts.jsx";
import { useNavigate } from "react-router-dom";

const ConnectYourAccounts = () => {
  const navigate = useNavigate();
  const handleAkahuModal = () => {
    navigate("/connect-akahu-verify-email");
  };

  return (
    <>
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="logoCss text-center mb-2">
                <WordLogoBlack />
              </div>
              <motion.div
                className="loginFormWrp"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.5 }}
                style={{
                  width: "1178px",
                  //   height: "710px",
                  borderRadius: "20px",
                  padding: "30px",
                }}
              >
                <div className="d-flex flex-row align-content-center justify-content-between">
                  <div>
                    <p
                      className="bold popover-live"
                      style={{
                        fontSize: "24px",
                        marginBottom: "5px",
                        fontWeight: "700",
                      }}
                    >
                      Connect your accounts
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        marginBottom: "10px",
                      }}
                    >
                      Connect to all of your bank, kiwisaver and investment
                      accounts to bring everything into one place.
                    </p>
                  </div>

                  <div
                    className="modal-popup-btn-wrp"
                    style={{
                      marginTop: "10px",
                      textAlign: "start",
                    }}
                  >
                    <button
                      className="change-password-btn cursor-pointer"
                      type="button"
                      style={{
                        marginTop: "0px",
                        marginBottom: "10px",
                        width: "223px",
                      }}
                      onClick={() => navigate("/all-set-up")}
                    >
                      Skip this step
                    </button>
                  </div>
                </div>

                <div className="row gx-5">
                  {/* Banks Section */}
                  <div className="" style={{ width: "19%" }}>
                    <div className="mt-2">
                      <p
                        className="mb-0"
                        style={{ fontSize: "12px", fontWeight: "700" }}
                      >
                        Banks
                      </p>
                      <BankConnectionAccounts
                        mainCss={"row justify-content-between"}
                        connections={iconCategoriesWithHeadings.bankConnections}
                        onIconClick={handleAkahuModal}
                      />
                    </div>
                  </div>

                  {/* Kiwisaver Section */}
                  <div className="" style={{ width: "19%" }}>
                    <div className="mt-2">
                      <p
                        className="mb-0"
                        style={{ fontSize: "12px", fontWeight: "700" }}
                      >
                        Kiwisaver
                      </p>
                      <BankConnectionAccounts
                        mainCss={"row justify-content-between"}
                        connections={
                          iconCategoriesWithHeadings.kiwiSaverConnections
                        }
                        onIconClick={handleAkahuModal}
                      />
                    </div>
                  </div>

                  {/* Share Portfolios Section */}
                  <div className="" style={{ width: "19%" }}>
                    <div className="mt-2">
                      <p
                        className="mb-0"
                        style={{ fontSize: "12px", fontWeight: "700" }}
                      >
                        Share portfolios
                      </p>
                      <BankConnectionAccounts
                        mainCss={"row justify-content-between"}
                        connections={
                          iconCategoriesWithHeadings.addInvestmentConnections
                        }
                        onIconClick={handleAkahuModal}
                      />
                    </div>
                  </div>

                  <div className="" style={{ width: "43%" }}>
                    <div className="mt-2">
                      <div
                        className=""
                        style={{
                          marginTop: "10px",
                          padding: "40px",
                          paddingTop: "35px",
                          background: "#D8E8ED",
                          borderRadius: "20px",
                        }}
                      >
                        <div className="d-flex align-items-center mb-3 akahu-modal-title">
                          <div className="bank-connection-img">
                            <Akahu />
                          </div>
                          <div className="bold black who-akahu">
                            Connect using Akahu
                          </div>
                        </div>
                        <div className="setting_text_color mt-2">
                          Akahu is New Zealand’s open finance provider.
                        </div>
                        <div className="setting_text_color mt-4">
                          Akahu makes it simple to securely access your
                          financial information and to provide that data with
                          platforms like BudgetBuddie.
                        </div>
                        <div className="setting_text_color mt-3">
                          Akahu uses 256-bit bank-grade encryption to keep your
                          data safe and secure.
                        </div>
                        <div className="setting_text_color mt-3">
                          With your consent, the secure connection with Akahu
                          will pull through your bank balances, transactional
                          information, and account details.
                        </div>
                        <div className="setting_text_color mt-3">
                          You’ll be redirected to Akahu’s website to establish
                          the connection.
                        </div>
                        <div className="setting_text_color mt-3">
                          To connect your accounts, tap the below button.{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
              <div className="d-flex flex-row align-items-center gap-1 mt-3">
                <p
                  style={{
                    fontSize: "14px",
                    color: "#B7B8C1",
                    fontWeight: "500",
                    margin:"0px"
                  }}
                >
                  Missed something?
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#469B88",
                    fontWeight: "700",
                    cursor:"pointer",
                    margin:"0px"
                  }}
                  onClick={() => navigate(-1)} 
                >
                  
                  Go back a step
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ConnectYourAccounts;
