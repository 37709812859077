import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import MainSidebar from "../../Components/MainSidebar/index.jsx";
import PopupColorModal from "../../ModalForm/Popup/PopupColorModel.jsx";
import weburls from "../../Weburls/weburls.jsx";
import {
  colorCss,
  customColourOrName,
  customColourOrNameColour,
  hexToColorMap,
  howOftenData,
  moduleInfoPopUpData,
  selectionColor,
} from "../../utilities/Statics/index.jsx";
import { parseYourAccountBalancesData } from "../../utilities/helper.jsx";
import OverlayContainer from "../../Components/OverlayContainer/OverlayContainer.jsx";
import { Loading } from "../../Components/Loading/Loading.jsx";
import EmojiPicker from "emoji-picker-react";
import { ConnectedAccounts } from "../../Components/ConnectedAccountsCard/index.jsx";
import Cinput from "../../Components/Cinput/index.jsx";
import PageTitle from "../../Components/PageTitle/index.jsx";
import UserImageInfoToggle from "../../Components/UserImageInfoToggle/index.jsx";
import SectionTitle from "../../Components/SectionTitle.jsx/index.jsx";

const schema = yup.object().shape({
  goalName: yup
    .string()
    .min(3, "*goalName is Required")
    .required("*Name is Required"),
  value: yup.string().required("*Goal dollar value  is Required"),
  contribution: yup.string().required("*Contribution is Required"),
  currentBalance: yup.string().required("*Balance is Required"),
});
const AddNewFinancial = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [selectedTab, setSelectedTab] = useState("Weekly");
  const [accountData, setAccountData] = useState([]);
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLinkedToAccount, setIsLinkedToAccount] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState("😁");
  const [showPicker, setShowPicker] = useState(false);
  const navigate = useNavigate();
  const [selectedAccounts, setSelectedAccounts] = useState([]);

  const [goalData, setGoalData] = useState({
    goalName: "",
    value: "",
    contribution: "",
    howOften: "",
    currentBalance: "",
    reference: "",
    colour: "Red",
    icon: "😁",
    startDate: null,
    endDate: null,
  });

  const [isPopupOpenColor, setPopupOpenColor] = useState(false);
  const { uuid } = useParams();
  const token = localStorage.getItem("budget-token");
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  const fetchCashAccount = useCallback(async () => {
    setLoading(true);

    try {
      const response = await axios.get(`${weburls.Get_Cash_Account}${uuid}`, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });
      if (response?.data) {
        const parsedData = parseYourAccountBalancesData(
          response?.data?.accounts
        );
        setAccountData(parsedData);
      }
      setLoading(false);
    } catch (error) {
      console.error("error", error);

      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }, [uuid]);

  useEffect(() => {
    fetchCashAccount();
  }, [fetchCashAccount]);

  const handleEmojiClick = (event) => {
    const { emoji } = event;
    setChosenEmoji(emoji);
    setShowPicker(false);
    setGoalData({
      ...goalData,
      icon: emoji,
    });
  };

  const handleTabChange = (tabName) => {
    const { value } = tabName.target;
    setSelectedTab(value);
    setGoalData({ ...goalData, howOften: value });
  };
  const togglePopupColor = () => setPopupOpenColor(!isPopupOpenColor);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGoalData({
      ...goalData,
      [name]: value,
    });
  };

  const handleOptionColorClick = (option) => {
    setGoalData({ ...goalData, colour: option });
    setPopupOpenColor(false);
  };
  const addGoal = async (data) => {
    try {
      const response = await axios.post(
        weburls.Add_Goals + uuid,
        {
          ...goalData,
          ...data,
          userUuid: uuid,
          reference: goalData.goalName.slice(0, 12),
        },
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Goal successfully..", {
          position: "top-right",
          autoClose: 2000,
        });
        navigate(`/goals/${uuid}`);
      } else if (response.status === 500) {
        toast.error("Goal details not saved. Please try again.");
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleToggleSwitch = (data) => {
    const { accountId } = data;

    setSelectedAccounts((prevAccounts) => {
      const newAccounts = prevAccounts.includes(accountId)
        ? prevAccounts.filter((id) => id !== accountId)
        : [...prevAccounts, accountId];

      const currentBalance = accountData
        .filter((acc) => newAccounts.includes(acc.accountId))
        .reduce((total, acc) => total + acc.value, 0);

      setGoalData((prevGoalData) => ({
        ...prevGoalData,
        accounts: newAccounts,
        isConnected: true,
        currentBalance: currentBalance,
      }));
      setValue("currentBalance", currentBalance);
      return newAccounts;
    });
  };

  return (
    <>
      <div className="app-main__outer financial-goals-main">
        <ToastContainer position="top-right" autoClose={5000} closeOnClick />
        <div className="row">
          <PageTitle
            title={"Your Financial Goals"}
            description={
              "Let's set some goals, it’s always good having goals and something to aim for."
            }
          />
          <UserImageInfoToggle
            title={moduleInfoPopUpData.goals.title}
            description={moduleInfoPopUpData.goals.description}
            isPopupOpenInfo={isPopupOpenInfo}
            togglePopupInfo={togglePopupInfo}
          />
        </div>
        <div className="row">
          <div className="d-flex gap-5 new-goal-container">
            <div className="col-lg-6 col-md-12 add-new-goal-wrpp">
              <div className="edit-goal-ami">
                <form onSubmit={handleSubmit(addGoal)}>
                  <div>
                    <SectionTitle title={"New goal"} />
                    {/* <div className="bold sub-head-text">New goal</div> */}
                    <div className="edit-goal-box d-flex justify-content-between align-items-center">
                      <div className="fw-bold">Name</div>
                      <Controller
                        name="goalName"
                        control={control}
                        render={({ field }) => (
                          <Cinput {...field} type="text" width={"336px"} />
                        )}
                      />
                    </div>
                    <p className="error-message">{errors.goalName?.message}</p>
                    <div className="edit-goal-box d-flex justify-content-between align-items-center">
                      <div className="fw-bold">Goal dollar value</div>
                      <Controller
                        name="value"
                        control={control}
                        render={({ field }) => (
                          <Cinput {...field} type="number" width={"226px"} />
                        )}
                      />
                    </div>
                    <p className="error-message">{errors.value?.message}</p>
                    <div className="edit-goal-box">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="fw-bold">Start Date</div>
                        <input
                          type="date"
                          name="startDate"
                          value={goalData.startDate}
                          onChange={handleChange}
                          className="edit-goal-house  py-2 add-goal"
                          style={{ border: "none" }}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-3">
                        <div className="fw-bold mt-1">Contributions</div>
                        <Controller
                          name="contribution"
                          control={control}
                          render={({ field }) => (
                            <Cinput {...field} type="number" width={"226px"} />
                          )}
                        />
                      </div>
                      <p className="error-message">
                        {errors.contribution?.message}
                      </p>
                      <div className="d-flex justify-content-between mt-2 align-items-center">
                        <div className="fw-bold">How often?</div>
                        <div className="account-input">
                          <select
                            className="how-often-select"
                            name="accountType"
                            onChange={handleTabChange}
                            value={selectedTab}
                          >
                            <option value="">Select</option>
                            {howOftenData &&
                              howOftenData.length > 0 &&
                              howOftenData.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.title}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="edit-goal-box">
                      <div className="d-flex justify-content-between mt-2 align-items-center">
                        <div className="fw-bold">Link to an account?</div>
                        <div className="account-input">
                          {isLinkedToAccount ? (
                            <div
                              className="link-account"
                              onClick={() =>
                                setIsLinkedToAccount(!isLinkedToAccount)
                              }
                            >
                              Yes
                            </div>
                          ) : (
                            <div
                              className="not-link-account"
                              onClick={() =>
                                setIsLinkedToAccount(!isLinkedToAccount)
                              }
                            >
                              No
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mt-3 align-items-center">
                        {isLinkedToAccount ? (
                          <>
                            <div className="fw-bold">Account</div>
                            <div className="account-input">
                              <button
                                type="button"
                                className={`connected-account`}
                                style={{ background: "#0477B5" }}
                                aria-haspopup="true"
                                aria-expanded={isPopupOpenColor}
                              >
                                {selectedAccounts.length}
                                &nbsp;connected&nbsp;accounts
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="current-bal">
                              <div className="fw-bold">Current balance</div>
                              <Controller
                                name="currentBalance"
                                control={control}
                                rules={{
                                  required: !isLinkedToAccount
                                    ? "Current balance is required"
                                    : false,
                                }}
                                render={({ field }) => (
                                  <Cinput
                                    {...field}
                                    type="number"
                                    width={"226px"}
                                  />
                                )}
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <p className="error-message">
                        {errors.currentBalance?.message}
                      </p>
                    </div>
                    <div className="edit-goal-box d-flex justify-content-between">
                      <div className="fw-bold">Personalized color</div>
                      <div className="color-option">
                        <div className="dropdown">
                          <button
                            className={`group-button ${customColourOrNameColour(
                              goalData.colour
                            )} popoup-color`}
                            onClick={togglePopupColor}
                            aria-expanded={isPopupOpenColor}
                            type="button"
                            aria-haspopup="true"
                            id="colorPickerButtonNewGoals"
                            style={{
                              color: "white",
                              backgroundColor: customColourOrNameColour(
                                goalData.colour
                              ),
                              padding: "4px 32px 5px 32px",
                              fontSize: "15px",
                              fontWeight: "500",
                            }}
                          >
                            {customColourOrName(goalData.colour)}
                          </button>
                          {isPopupOpenColor && (
                            <PopupColorModal
                              isOpen={isPopupOpenColor}
                              targetId="colorPickerButtonNewGoals"
                              onClose={togglePopupColor}
                              title="Pick your colour"
                              options={[
                                "Red",
                                "Yellow",
                                "Blue",
                                "Purple",
                                "Orange",
                                "Pink",
                                "Light blue",
                                "Light green",
                                "Green",
                              ]}
                              selectedOption={goalData.colour}
                              handleOptionClick={handleOptionColorClick}
                            />
                          )}
                        </div>
                      </div>
                      {/* <div
                        className="color-option"
                        style={{ marginRight: "30px" }}
                      >
                        <div className="dropdown">
                          <button
                            type="button"
                            className={`group-button ${colorCss(
                              goalData.colour
                            )} popoup-color`}
                            onClick={togglePopupColor}
                            aria-haspopup="true"
                            aria-expanded={isPopupOpenColor}
                          >
                            {goalData.colour ? goalData.colour : "Red"}
                          </button>

                          {isPopupOpenColor && (
                            <PopupColorModal
                              isOpen={isPopupOpenColor}
                              onClose={togglePopupColor}
                              title="Date Range"
                              options={[
                                "Red",
                                "Yellow",
                                "Blue",
                                "Purple",
                                "Orange",
                                "Pink",
                                "Light blue",
                                "Green",
                              ]}
                              selectedOption={goalData?.colorOption}
                              handleOptionClick={handleOptionColorClick}
                            />
                          )}
                        </div>
                      </div> */}
                    </div>
                    <div className="edit-goal-box d-flex justify-content-between flex-column">
                      <div className="d-flex justify-content-between w-100">
                        <div className="fw-bold">Personalized emoji</div>
                        <div
                          className="py-2 add-goal w-50 mx-4 show-emoji"
                          onClick={() => setShowPicker(!showPicker)}
                        >
                          {chosenEmoji}
                        </div>
                      </div>
                      {showPicker && (
                        <div className="new-emoji-container">
                          <EmojiPicker onEmojiClick={handleEmojiClick} />
                        </div>
                      )}
                    </div>
                    <div className="profile-dashboard mt-3 ms-0">
                      <button type="submit" className="comn-btn add-goals-btn">
                        Add Goal
                        <i className="fa-solid fa-plus plus-icon-style"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="add-goal-paragraph d-flex align-items-center">
                <div className="fw-bold tip-500">Tip:&nbsp;</div>
                <div className="goal-tip">
                  Create separate bank accounts for each goal to keep on track
                  of your progress.
                </div>
                <br />
              </div>
            </div>
            {isLinkedToAccount && (
              <div>
                <SectionTitle title={"Link to an account"} />
                <div className="d-flex flex-wrap link-account-gap">
                  {accountData?.map((ele, index) => (
                    <ConnectedAccounts
                      key={index}
                      avatar={ele?.avatar}
                      accountName={ele?.accountName}
                      value={ele?.value}
                      accountId={ele?.accountId}
                      width={"49%"}
                      selectedAccount={selectedAccounts}
                      id={ele?.accountId}
                      handleToggleSwitch={handleToggleSwitch}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          {loading && (
            <OverlayContainer>
              <div>
                <Loading />
              </div>
            </OverlayContainer>
          )}
        </div>
      </div>
    </>
  );
};

export default AddNewFinancial;
