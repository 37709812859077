import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
import EmailOtp from "../../assests/images/email-gif.gif";
import { ReactComponent as WordLogoBlack } from "../../assests/images/WordLogoBlack.svg";
import "./Login.css";
import OtpBox from "./OtpBox";
import weburls from "../../Weburls/weburls";

const VerifyResetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emailOtp, setEmailOtp] = useState("");
  const logoRef = useRef(null);
  const formRef = useRef(null);

  const userUuid = localStorage.getItem("userUuid");

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("slide-up");
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
    };
  }, []);

  const onSubmitHandler = async () => {
    if (emailOtp.length === 6) {
      const body = {
        otp: emailOtp,
        userUuid: userUuid,
      };

      setLoading(true);
      try {
        const response = await axios.post(
          weburls.Check_Reset_Password_Otp,
          body
        );
        if (response.data.message && Number(response.data.code) === 0) {
          toast.success("Verification successful!");
          navigate("/set-new-password");
        } else {
          toast.error(response.data.message || "Failed to verify the OTP.");
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Please enter a 6-digit code");
    }
  };

  const handleOtpSubmit = (otp) => {
    setEmailOtp(otp);
  };

  return (
    <>
      <style>{`
        .slide-up {
          animation: slide-up 0.5s ease-out forwards;
        }

        @keyframes slide-up {
          0% {
            transform: translateY(80px);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
      <section className="login-section loginSectionWelcome">
        <ToastContainer position="top-right" autoClose={5000} closeOnClick />
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="logoCss text-center mb-4" ref={logoRef}>
                <WordLogoBlack />
              </div>

              <div className="loginFormWrp" ref={formRef}>
                <div className="text-center">
                  <img src={EmailOtp} className="email-gif" alt="Email OTP" />
                </div>
                <p
                  className="text-center mt-3 emailText margin-botton-head text-size-heading-login"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  We have sent a verification code to your email.
                </p>
                <p
                  className="text-center mb-4 mt-3 tagtext"
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  Check your email and enter the code below to verify it’s you.
                </p>
                <OtpBox
                  length={6}
                  onSubmit={handleOtpSubmit}
                  className="verification-box"
                />
                <div className="text-center mb-3">
                  <button
                    className={`comn-btn ${
                      loading
                        ? "verifyresetpassword-btn-load"
                        : "verifyresetpassword-btn"
                    }`}
                    onClick={onSubmitHandler}
                  >
                    {loading ? (
                      <ClipLoader size={15} color={"#ffffff"} />
                    ) : (
                      "Submit verification code"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VerifyResetPassword;
