import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import { ReactComponent as WordLogoBlack } from "../../assests/images/WordLogoBlack.svg";
import "./Login.css";
import weburls from "../../Weburls/weburls";
import { ClipLoader } from "react-spinners";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Please enter a valid email")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
      "Please enter a proper email"
    ),
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const logoRef = useRef(null);
  const formRef = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    setFormData({
      ...formData,
      [name]: trimmedValue,
    });
  };

  const onSubmitHandler = async (formData) => {
    setLoading(true);
    try {
      const response = await axios.post(weburls.Forgot_Password, formData);
      setLoading(false);
      if (response.data.code === 1) {
        localStorage.setItem("userUuid", response.data.userUuid);
        navigate(`/verify-reset-password`);

        toast.success("Reset Password link send into email successfully", {
          position: "top-right",
          autoClose: 2000,
        });
      }

      if (response.data.code === 0) {
        toast.error("User Not Found. Please enter vaild email address", {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error details:", error);
    
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };
    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("slide-up");
          observer.unobserve(entry.target);
        }
      });
    };
    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );
    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);
    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
    };
  }, []);

  return (
    <>
      <style>{`
      .slide-up {
        animation: slide-up 0.5s ease-out forwards;
      }

      @keyframes slide-up {
        0% {
          transform: translateY(80px);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }
    `}</style>
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="logoCss text-center mb-4" ref={logoRef}>
                <WordLogoBlack />
              </div>

              <div
                className="loginFormWrp"
                style={{ borderRadius: "20px", padding: "30px" }}
                ref={formRef}
              >
                <p
                  className="text-center	mb-2 LoginText text-size-heading-login"
                  style={{ fontWeight: "600" }}
                >
                  Forgot Password?
                </p>
                <p
                  className="text-center	mb-5 forgotDescriptionText"
                  style={{
                    fontWeight: "400",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    lineHeight: "18px",
                    fontSize: "14px",
                  }}
                >
                  Don’t worry, it happens to the best of us! Please enter your
                  email below and we’ll send you a verification code to reset
                  it.
                </p>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="form-outline mb-3">
                    <div
                      className="input-header-text"
                      style={{ fontSize: "18px" }}
                    >
                      Email
                    </div>
                    <div
                      className="welcome-form-input-box"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        className="input-text-box-margin"
                        style={{
                          paddingTop: "13px",
                          paddingBottom: "13px",
                          width: "100%",
                        }}
                      >
                        <input
                          {...register("email", {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                          value={formData.email}
                          name="email"
                          type="text"
                          id="form3Example3"
                          className="register-focus"
                          style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            border: "none",
                            outline: "none",
                            width: "100%",
                            fontFamily: "Neurialgrotesk",
                          }}
                          placeholder="Enter a valid Email"
                        />
                      </div>
                    </div>
                    <p className="error-message">{errors.email?.message}</p>
                  </div>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    closeOnClick
                  />
                  <div className="text-center mt-4 pt-2">
                    <button
                      type="submit"
                      className={`comn-btn ${
                        loading ? "forgot-btn-load" : "forgot-btn"
                      }`}
                      disabled={loading}
                    >
                      {loading ? (
                        <ClipLoader size={15} color={"#FFFFFF"} />
                      ) : (
                        "Submit email"
                      )}
                    </button>
                    <div className="d-flex flex-row align-items-center justify-content-center mt-4">
                      <span className="textContainer">
                        Remember your password?{" "}
                        <a
                          href="/"
                          className="text-green"
                          style={{ fontWeight: 700 }}
                        >
                          Go back
                        </a>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ForgotPassword;
