import React, { useState } from "react";
import PopOver from "./Popover";
import {
  categoryBackgroundCss,
  categoryColorForAll,
} from "../../utilities/Statics";
import PendingTransactionModel from "../../ModalForm/TransactionModals/PendingTransactionModel";
import AddCategoriesModal from "../../ModalForm/TransactionModals/AddCategoriesModal";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import weburls from "../../Weburls/weburls";
import axios from "axios";

const CategoryWithPopover = ({
  emoji,
  name,
  colour,
  style,
  uniqueID,
  amount,
  updateCategory,
  expenseCategories,
  incomeCategories,
  oneOffCategory,
  updatingForSplitTransaction = false,
  onCategoryChangeForSplitting,
  status = "",
  categoryTitle,
  categorySubTitle,
  customPopoverClassName,
}) => {
  const { uuid } = useParams();
  const token = localStorage.getItem("budget-token");
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [showAddCatogory, setShowAddCatogory] = useState(false);
  const [showPendingTransaction, setShowPendingTransaction] = useState(false);
  const togglePopover = () => {
    setPopoverOpen((prev) => !prev);
  };

  const addNewExpenseCategory = async (data) => {
    try {
      const response = await axios.post(
        `${weburls.Add_Expense_Category}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const addNewIncomeCategory = async (data) => {
    try {
      const response = await axios.post(
        `${weburls.Add_Income_Category}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const addNewCategory = (data, type) => {
    if (type === "Expense") {
      addNewExpenseCategory(data);
    } else {
      addNewIncomeCategory(data);
    }
  };

  const handleAddCategory = () => {
    setShowAddCatogory(!showAddCatogory);
    setPopoverOpen(!popoverOpen);
  };

  const handlePendingTransaction = () => {
    setShowPendingTransaction(!showPendingTransaction);
  };
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);
  return (
    <div id={`popOverDetail-${uniqueID}`}>
      <div
        className="button-blck"
        id={`Popover-${uniqueID}`}
        onClick={() =>
          status === "Pending"
            ? handlePendingTransaction()
            : togglePopover(uniqueID)
        }
      >
        <div className="icon-tag" style={style}>
          {name !== "Uncategorised" && name !== "Select a category" && (
            <div
              className={`icon-transaction-img ${
                isHexColor(colour) ? "" : categoryBackgroundCss(colour)
              }`}
              style={{
                background:
                  status === "Pending" ? "white" : categoryColorForAll(colour),
              }}
            >
              {status === "Pending" ? "⏳" : emoji}
            </div>
          )}
          <div className="content">
            <h6 className="icon-content">
              {status === "Pending" ? "Pending" : name}
            </h6>
          </div>
        </div>
      </div>

      {showPendingTransaction && (
        <PendingTransactionModel
          modalOpen={showPendingTransaction}
          closeModal={handlePendingTransaction}
        />
      )}

      {popoverOpen && (
        <PopOver
          categories={amount < 0 ? expenseCategories : incomeCategories}
          oneOffCategory={amount < 0 ? oneOffCategory : []}
          transactionId={uniqueID}
          target={`popOverDetail-${uniqueID}`}
          togglePopover={togglePopover}
          popoverOpen={popoverOpen}
          transactionType={amount < 0 ? "Expense" : "Income"}
          updateCategory={updateCategory}
          updatingForSplitTransaction={updatingForSplitTransaction}
          onCategoryChangeForSplitting={onCategoryChangeForSplitting}
          categoryTitle={categoryTitle}
          categorySubTitle={categorySubTitle}
          customPopoverClassName={customPopoverClassName}
          handleChange={handleAddCategory}
        />
      )}

      {showAddCatogory && (
        <AddCategoriesModal
          modalOpen={showAddCatogory}
          addNewCategory={addNewCategory}
          transactionType={amount < 0 ? "Expense" : "Income"}
          closeModal={handleAddCategory}
        />
      )}
    </div>
  );
};

export default CategoryWithPopover;
