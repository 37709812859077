import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import weburls from "../../Weburls/weburls";
import { ReactComponent as Logo } from "../../assests/images/icons/akahulogo.svg";
import axios from "axios";

const AkahuCallback = () => {
  const uuid = localStorage.getItem("userUuid");
  const token = localStorage.getItem("budget-token");
  const location = useLocation();
  const logoRef = useRef(null);
  const formRef = useRef(null);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(10);
  const apiCalled = useRef(false); 

  const onSubmitHandler = async () => {
    if (apiCalled.current) return; 
    apiCalled.current = true; 

    setLoading(true);
    try {
      const response = await axios.get(
        `${weburls.Akahu_Connection}${uuid}?code=${code}`,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data?.code === 1) {
        localStorage.setItem("trnas-load", true);
      }
      toast.success(`${response?.data?.message}`);
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong...");
      setLoading(false);
    }
  };

  useEffect(() => {
    onSubmitHandler(); 
    const navigateTimeout = setTimeout(() => {
      navigate(`/connectaccounts/${uuid}`);
    }, 10000);
    return () => clearTimeout(navigateTimeout);
  }, [navigate, uuid]); 

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("slide-up");
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
    };
  }, []);

  return (
    <>
      <style>{`
        .slide-up {
          animation: slide-up 0.5s ease-out forwards;
        }

        @keyframes slide-up {
          0% {
            transform: translateY(80px);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }

        .checkbox-round {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 2px solid #ccc;
          display: inline-block;
          margin-left: 10px;
          position: relative;
        }

        .checkbox-round.selected {
          background-color: #469b88; /* Theme green color */
          border-color: #469b88; /* Theme green color */
        }

        .tick-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 12px;
          height: 12px;
        }

        .Toastify__toast-container {
          position: fixed;
          top: 20px;
          right: 20px;
          z-index: 9999;
        }

        /* Custom placeholder color */
        .message-cancel-div::placeholder {
          color: #D3D3D3; /* Light gray */
        }
      `}</style>
      <div className="not-found-container">
        <section style={{ width: "100%" }} className="login-section">
          <div className="container-fluid h-custom">
            <div
              className="row d-flex justify-content-center align-items-center"
              style={{ height: "90%" }}
            >
              <div
                className="col-md-8 col-lg-6 col-xl-4"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="loginFormWrp" ref={formRef}>
                  <p className="text-center mb-1 mt-2 LoginText">
                    We’re loading in your accounts & transactions 💸
                  </p>
                  <div className="d-flex flex-row align-items-center justify-content-center mt-3">
                    <span className="notFoundContainer">
                      Won’t be long, we’re just tidying up your loose change &
                      counting your pennies.
                    </span>
                  </div>
                  <Logo />
                  <form>
                    <div className="text-center pt-2">
                      <button
                        type="submit"
                        className="comn-btn akahu-model-btn"
                        style={{ marginBottom: "20px" }}
                      >
                        {`Finishing in ${counter} seconds`}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AkahuCallback;
