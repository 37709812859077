import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import { ReactComponent as WordLogoBlack } from "../../assests/images/WordLogoBlack.svg";
import { ReactComponent as CloseEyeIcon } from "../../assests/images/icons/close_eye_icon.svg";
import { ReactComponent as OpenEyeIcon } from "../../assests/images/icons/open_eye_icon.svg";
import "./Login.css";
import weburls from "../../Weburls/weburls";
import { ClipLoader } from "react-spinners";
import { ReactComponent as Success } from "../../assests/images/icons/passwordSucess.svg";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("*Please enter your password")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Password must contain at least one letter, one number, one special character, and be at least 8 characters long"
    ),
  cpassword: yup
    .string()
    .required("*Please enter your confirm password")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

const SetNewPassword = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [passwordType, setPasswordType] = useState("password");
  const [passwordConfirmType, setPasswordConfirmType] = useState("password");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const logoRef = useRef(null);
  const formRef = useRef(null);

  const togglePassword = () => {
    setPasswordType((prev) => (prev === "password" ? "text" : "password"));
  };

  const toggleConfirmPassword = () => {
    setPasswordConfirmType((prev) =>
      prev === "password" ? "text" : "password"
    );
  };

  const [formData, setFormData] = useState({
    password: "",
    cpassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    setFormData({
      ...formData,
      [name]: trimmedValue,
    });
  };

  const userUuid = localStorage.getItem("userUuid");

  const onSubmitHandler = async (formData) => {
    setLoading(true);

    const requestData = {
      userUuid,
      newPassword: formData.password,
    };

    try {
      const response = await axios.post(
        weburls.Forgot_Set_New_Password,
        requestData
      );

      if (response.data.code === 0) {
        toast.error(response.data.message || "Failed to change the password.");
      } else {
        toast.success("Password changed successfully!");
        setSuccess(true);
        localStorage.removeItem("userUuid");
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Link is expired or Invalid. Please try again.");
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };
    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("slide-up");
          observer.unobserve(entry.target);
        }
      });
    };
    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );
    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);
    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
    };
  }, []);

  if (success) {
    return (
      <>
        <style>{`
        .slide-up {
          animation: slide-up 0.5s ease-out forwards;
        }

        @keyframes slide-up {
          0% {
            transform: translateY(80px);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
        <section className="login-section loginSectionWelcome">
          <div className="container-fluid h-custom">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div
                className="col-md-8 col-lg-6 col-xl-4"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="logoCss text-center mb-3" ref={logoRef}>
                  <WordLogoBlack />
                </div>

                <div
                  className="loginFormWrp"
                  style={{ padding: "20px" }}
                  ref={formRef}
                >
                  <div className="sucess-container transition-delay">
                    <div className="fs-3">
                      <span
                        className="bold reset-password-text text-size-heading-login"
                      >
                        Password Changed
                      </span>
                    </div>
                    <p className="sucess-p" style={{ padding: "0px 10px" }}>
                      Your password has now been updated. Login using this
                      password and enjoy all the features we have to offer.
                    </p>
                    <div className="success-img">
                      <Success />
                    </div>
                    <div className="modal-popup-btn-wrp">
                      <button
                        className={`comn-btn forgot-btn`}
                        type="button"
                        onClick={() => navigate("/")}
                      >
                        Back to login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <style>{`
      .slide-up {
        animation: slide-up 0.5s ease-out forwards;
      }

      @keyframes slide-up {
        0% {
          transform: translateY(80px);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }
    `}</style>
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="logoCss text-center mb-3" ref={logoRef}>
                <WordLogoBlack />
              </div>

              <div className="loginFormWrp" ref={formRef}>
                <p
                  className="text-center mb-3 mt-3 reset-password-text text-size-heading-login"
                >
                  Set new Password
                </p>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="form-outline ">
                    <label
                      className="input-header-text"
                      style={{ fontSize: "18px" }}
                      for="form3Example3"
                    >
                      Password
                    </label>
                    <div
                      className="welcome-form-input-box"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        className="formwrp input-text-box-margin"
                        style={{
                          paddingTop: "13px",
                          paddingBottom: "13px",
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <input
                          {...register("password", {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                          type={passwordType}
                          name="password"
                          id="password"
                          className="register-focus"
                          style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            border: "none",
                            outline: "none",
                            width: "100%",
                            fontFamily: "Neurialgrotesk",
                          }}
                          placeholder="Enter new password"
                        />
                        <div className="" onClick={togglePassword}>
                          {passwordType === "password" ? (
                            // <i className="fa-solid fa-eye"></i>
                            <OpenEyeIcon className="fa-solid " />
                          ) : (
                            // <i className="fa-solid fa-eye-slash"></i>
                            <CloseEyeIcon className="fa-solid " />
                          )}
                        </div>
                      </div>
                    </div>
                    <p className="mt-1 error-message">
                      {errors.password?.message}
                    </p>
                  </div>

                  <div className="form-outline mb-3">
                    <label
                      className="input-header-text"
                      style={{ fontSize: "18px", marginTop: "0px" }}
                      for="form3Example4"
                    >
                      Confirm password
                    </label>
                    <div
                      className="welcome-form-input-box"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        className="formwrp input-text-box-margin"
                        style={{
                          paddingTop: "13px",
                          paddingBottom: "13px",
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <input
                          {...register("cpassword", {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                          type={passwordConfirmType}
                          name="cpassword"
                          id="cpassword"
                          className="register-focus"
                          style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            border: "none",
                            outline: "none",
                            width: "100%",
                            fontFamily: "Neurialgrotesk",
                          }}
                          placeholder="Confirm new password"
                        />
                        <div className="" onClick={toggleConfirmPassword}>
                          {passwordConfirmType === "password" ? (
                            // <i className="fa-solid fa-eye"></i>
                            <OpenEyeIcon className="fa-solid " />
                          ) : (
                            // <i className="fa-solid fa-eye-slash"></i>
                            <CloseEyeIcon className="fa-solid " />
                          )}
                        </div>
                      </div>
                    </div>
                    <p className="mt-1 error-message">
                      {errors.cpassword?.message}
                    </p>
                  </div>

                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    closeOnClick
                  />
                  <div className="text-center mt-2 pt-2">
                    <button
                      type="submit"
                      className={`comn-btn mb-4 ${
                        loading ? "forgot-btn-load" : "forgot-btn"
                      }`}
                      disabled={loading}
                    >
                      {loading ? (
                        <ClipLoader size={15} color={"#FFFFFF"} />
                      ) : (
                        " Set new password"
                      )}
                    </button>
                    <div className="d-flex flex-row align-items-center justify-content-center mb-3">
                      <span className="textContainer">
                        Remember your password?{" "}
                        <a
                          href="/"
                          className="text-green"
                          style={{ fontWeight: 700 }}
                        >
                          Go back
                        </a>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default SetNewPassword;
