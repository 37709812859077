import React, { useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import "./feedback_popUp.css";
import { ReactComponent as Success } from "../../../assests/images/icons/passwordSucess.svg";
import weburls from "../../../Weburls/weburls";
import { toast } from "react-toastify";

const FeatureTextareaPopUp = ({
  isOpen,
  onClose,
  heading,
  desc,
  isLastStep,
  onNext,
  setIsModalTypeFeedbackOpen,
  feedbackType,
  feedbackFeature,
}) => {
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [allfeedbackMessage, setAllFeedbackMessage] = useState([]);
  const [thankYouPopUp, setThankYouPopUp] = useState(false);
  const token = localStorage.getItem("budget-token");
  const userUuid = localStorage.getItem("userUuid");
  const userData = localStorage.getItem("userData");
  const parsedUserData = userData ? JSON.parse(userData) : null;

  const handleInputChange = (event) => {
    setFeedbackMessage(event.target.value);
  };

  const TypeFeedback = (type) => {
    const feedbackTypes = [
      { type: "feature-request", label: "Feature request" },
      { type: "feature-feedback", label: "Feature feedback" },
      { type: "report-bug", label: "Report a bug" },
      { type: "general-feedback", label: "General feedback" },
    ];

    const feedback = feedbackTypes.find((item) => item.type === type);

    return feedback ? feedback.label : "Unknown feedback type";
  };

  const handleNext = async () => {
    setAllFeedbackMessage([...allfeedbackMessage, feedbackMessage]);
    setFeedbackMessage("");

    if (!onNext || typeof onNext !== "function") {
      const payload = [
        {
          feedbackType: TypeFeedback(feedbackType),
          feedbackFeature: "",
          feedbackComment: feedbackMessage,
          firstName: parsedUserData?.firstName,
          email: parsedUserData?.username,
        },
      ];

      try {
        await axios.post(
          `${weburls.Submit_Feedback}${userUuid}?userDevice=Web`,
          payload,
          {
            headers: {
              Authorization: `Basic ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        setThankYouPopUp(true);
      } catch (error) {
        console.error("Error submitting feedback", error);
        toast.error("Failed to submit feedback. Please try again.", {
          position: "top-right",
          autoClose: 2000,
        });
      }
      return;
    }

    if (isLastStep) {
      const payload =
        feedbackFeature &&
        feedbackFeature.length > 0 &&
        feedbackFeature.map((feature, index) => ({
          feedbackType: TypeFeedback(feedbackType),
          feedbackFeature: feature,
          feedbackComment:
            index === feedbackFeature.length - 1
              ? feedbackMessage
              : allfeedbackMessage[index],
          firstName: parsedUserData?.firstName,
          email: parsedUserData?.username,
        }));

      try {
        await axios.post(
          `${weburls.Submit_Feedback}${userUuid}?userDevice=Web`,
          payload,
          {
            headers: {
              Authorization: `Basic ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        setThankYouPopUp(true);
      } catch (error) {
        console.error("Error submitting feedback", error);
        toast.error("Failed to submit feedback. Please try again.", {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } else {
      onNext();
    }
  };

  const handleCloseThankYouModal = () => {
    setIsModalTypeFeedbackOpen(false);
  };

  if (thankYouPopUp) {
    return (
      <>
        <div className="modal-overlay" isOpen={isOpen} toggle={onClose}>
          <motion.div
            className="modal-container"
            style={{
              padding: "20px",
              paddingTop: "10px",
              paddingBottom: "10px",
              borderRadius: "20px",
              maxWidth: "450px",
            }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.5 }}
          >
            <div className="transaction-categories mt-2">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="bold popover-live"
                  style={{ fontSize: "24px", marginBottom: "5px" }}
                >
                  Thank you!
                </div>
              </div>
              <p style={{ fontSize: "14px", marginBottom: "10px" }}>
                Thanks for taking the time to provide your feedback, it means a
                lot to us.
              </p>
              <p style={{ fontSize: "14px", marginBottom: "10px" }}>
                Our team will review your feedback or feature request and will
                get back to you as soon as we can.
              </p>
              <div className="success-img" style={{ textAlign: "center" }}>
                <Success />
              </div>
              <div className="modal-popup-btn-wrp">
                <button
                  className="change-password-btn cursor-pointer"
                  type="button"
                  onClick={handleCloseThankYouModal}
                  style={{
                    marginTop: "20px",
                    marginBottom: "10px",
                  }}
                >
                  Finish
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      </>
    );
  }

  return (
    <div className="modal-overlay" isOpen={isOpen} toggle={onClose}>
      <motion.div
        className="modal-container"
        style={{
          padding: "20px",
          paddingTop: "10px",
          paddingBottom: "10px",
          borderRadius: "20px",
          maxWidth: "450px",
        }}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.5 }}
      >
        <div className="transaction-categories mt-2">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="bold popover-live" style={{ fontSize: "24px" }}>
              {heading}
            </div>
          </div>
          <p style={{ fontSize: "14px", marginBottom: "10px" }}>{desc}</p>

          <textarea
            value={feedbackMessage}
            onChange={handleInputChange}
            name="feedbackMessage"
            type="text"
            id="form3Example3"
            className="feedbackMessage-textarea"
            placeholder="Spill the beans here..."
          />

          <div
            className="flex-row d-flex justify-content-between align-items-center mb-2"
            style={{ width: "100%", gap: "10px" }}
          >
            <button
              className="revoke-red-btn cursor-pointer"
              onClick={onClose}
              style={{
                width: "210px",
                marginTop: "40px",
                marginBottom: "10px",
                height: "44px",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              Back
            </button>
            <button
              className="change-password-btn cursor-pointer"
              onClick={handleNext}
              type="submit"
              style={{
                width: "210px",
                marginTop: "40px",
                marginBottom: "10px",
                height: "44px",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              {isLastStep ? "Submit" : "Next"}
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default FeatureTextareaPopUp;