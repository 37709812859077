// export const BudgetBuddie = 'http://localhost:5010/';
export const BudgetBuddie = process.env.REACT_APP_BACKEND_URL;
export const BudgetBuddieAkahuAPI = process.env.REACT_APP_AKAHU_API_URL;


// export const BudgetBuddie = 'https://app.budgetbuddie.com/';

// Advisor login api

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Signup: BudgetBuddie + 'api/clients/signup',
  Check_one_time_token: BudgetBuddie + 'api/clients/checkUserOneTimeToken/',
  // Advisor_Login: BudgetBuddie + 'api/developer-apis/clients/v1/user/login', -- Old login API Url 
  Advisor_Login: BudgetBuddie + 'api/developer-apis/auth/v1/user/login',
  Advisor_Details: BudgetBuddie + 'api/advisor/getAdvisorByUser/',
  Advisor_Details_Update: BudgetBuddie + 'api/advisor/updateAdvisor',
  Advisor_role_list: BudgetBuddie + 'api/role/getRoles',
  Advisor_list: BudgetBuddie + 'api/advisor/getAdvisorByCompany/',
  Add_advisor: BudgetBuddie + 'api/advisor/addAdvisor',
  updateAdvisorDashboard: BudgetBuddie + 'api/advisor/updateAdvisorDetail',
  //    super admin add company
  Super_admin_company_add: BudgetBuddie + 'api/super_admin/company',
  Super_admin_company_list: BudgetBuddie + 'api/super_admin/company',
  Super_admin_add_plan: BudgetBuddie + 'api/plan/addPlan',
  Super_admin_plans_list: BudgetBuddie + 'api/plan/list',
  Super_admin_edit_plans: BudgetBuddie + 'api/plan/getPlanById/',
  Super_admin_update_plans: BudgetBuddie + 'api/plan/updatePlan',
  Super_admin_delete_plans: BudgetBuddie + 'api/plan/deletePlan/',
  Super_admin_status_edit: BudgetBuddie + 'api/plan/updatePlanStatus',

  //Groups api
  Add_group: BudgetBuddie + 'api/groups/addGroup',
  Group_list: BudgetBuddie + 'api/groups/list',
  Get_group_details: BudgetBuddie + 'api/groups/getGroupById/',
  Update_group: BudgetBuddie + 'api/groups/updateGroup',
  Delete_group: BudgetBuddie + 'api/groups/delete/',
  Get_Group_With_Clients: BudgetBuddie + 'api/groups/getGroupWithClients',

  //Clients api

  Add_clients: BudgetBuddie + 'api/clients/addClient',
  Get_clients: BudgetBuddie + 'api/clients/getClientsByCompany/',
  Get_clients_cliendid: BudgetBuddie + 'api/clients/getClientsByUUId/',
  Update_clients: BudgetBuddie + 'api/clients/updateClient',
  Get_clients_by_uuid: BudgetBuddie + 'api/clients/getClientByUuid/',
  deleteClientById: BudgetBuddie + 'api/clients/deleteClients/',
  Get_User_Details: BudgetBuddie + 'api/developer-apis/clients/v1/user/userAllDetails/',
  // Edit budget Page

  Add_income: BudgetBuddie + 'api/budgetSummary/incomes',
  Update_Income: BudgetBuddie + 'api/budgetSummary/updateIncome',
  Get_Income: BudgetBuddie + 'api/budgetSummary/incomes/',
  Get_Income_ById: BudgetBuddie + 'api/budgetSummary/incomes/getIncomeById/',
  Delete_income: BudgetBuddie + 'api/budgetSummary/incomes/',
  Get_Monthly_Income_Expense: BudgetBuddie + 'api/budgetSummary/getIncomeAndExpense/',
  Get_Income_Vs_Budget: BudgetBuddie + 'api/budgetSummary/getIncomeVsBudgetDetails/',
  Get_Income_BreakDown: BudgetBuddie + 'api/budgetSummary/getIncomeBreakDown/',

  Add_expense: BudgetBuddie + 'api/budgetSummary/addExpenses',
  Get_Expense: BudgetBuddie + 'api/budgetSummary/expenses/',
  Update_Expense: BudgetBuddie + 'api/budgetSummary/updateExpenses',
  Get_Expense_By_Id: BudgetBuddie + 'api/budgetSummary/expenses/getExpensById/',
  Get_Expense_And_Transfer_By_Id: BudgetBuddie + 'api/budgetSummary/getAllExpenseAndTransfer/',
  Delete_Expense: BudgetBuddie + 'api/budgetSummary/expenses/',
  Get_Spend_Vs_Budget: BudgetBuddie + 'api/budgetSummary/getSpendVsExpenseDetails/',
  Get_Expense_Break_Down: BudgetBuddie + 'api/budgetSummary/getExpenseBreakDown/',
  Get_Transaction_By_Expense_Id: BudgetBuddie + 'api/budgetSummary/getExpenseTransactions/',

  Account_Transfer_Add: BudgetBuddie + 'api/budgetSummary/addAccountTransfer',
  Update_Account_Transfer: BudgetBuddie + 'api/budgetSummary/updateAccountTransfer',
  Account_Transfer_Get_List: BudgetBuddie + 'api/budgetSummary/getAccountTransfer/',
  Get_Account_Transfer_By_Id: BudgetBuddie + 'api/budgetSummary/getAccountTransfer/getAccountTransferById/',
  Delete_Account_Transfer: BudgetBuddie + 'api/budgetSummary/deleteAccountTransfer/',

  How_Often: BudgetBuddie + 'api/howOften/getHowOften',

  // Assets

  Add_Assets: BudgetBuddie + 'api/developer-apis/v1/networth/assets',
  Get_Assets: BudgetBuddie + 'api/developer-apis/v1/networth/assets/',
  Get_Asset_By_Id: BudgetBuddie + 'api/networth/assets/getAssetById/',
  Update_Asset: BudgetBuddie + 'api/developer-apis/v1/networth/updateAssets',
  Delete_Asset: BudgetBuddie + 'api/developer-apis/v1/networth/assets/',
  Get_NetWorth_Progress: BudgetBuddie + 'api/networth/getNetWorthProgress/',

  // Liability

  Add_Liability: BudgetBuddie + 'api/developer-apis/v1/networth/liability',
  Get_Liability: BudgetBuddie + 'api/developer-apis/v1/networth/liability/',
  Get_Liability_By_Id: BudgetBuddie + 'api/networth/liability/getLiabilityById/',
  Update_Liability: BudgetBuddie + 'api/developer-apis/v1/networth/updateLiability',
  Delete_Liability: BudgetBuddie + 'api/developer-apis/v1/networth/liability/',

  // Cash Account

  Add_Cash_Account: BudgetBuddie + 'api/developer-apis/cashAccount/addCashAccount',
  Get_Cash_Account: BudgetBuddie + 'api/developer-apis/cashAccount/getCashAccount/',
  Delete_Cash_Account: BudgetBuddie + 'api/cashAccount/deleteCashAccount/',
  Update_Cash_Account: BudgetBuddie + 'api/developer-apis/cashAccount/updateCashAccount',
  Get_Account_Transactions: BudgetBuddie + 'api/developer-apis/cashAccount/getAccountTransactionUsingUserUuidAndAccountUuidWithFilter/',
  Delete_cash_account: BudgetBuddie + 'api/developer-apis/cashAccount/deleteCashAccount/',
  // Akahu Accounts

  Get_Aku_Accounts: BudgetBuddie + 'api/developer-apis/auth/akahu/getAccounts/',
  Revoke_Akahu_Accounts: BudgetBuddieAkahuAPI + "akahuApi/account/revokeAllConnections/",
  Revoke_Single_Account: BudgetBuddieAkahuAPI + "akahuApi/account/revokeAccessByAccountId/",
  Refresh_All_Account: BudgetBuddieAkahuAPI + "akahuApi/account/refreshAllAccount/",

  // Get_Aku_Accounts: BudgetBuddie + 'api/auth/akahu/getAccounts/',

// Retirement Planner
Get_Selected_Retirement_Planner_Accounts: BudgetBuddie+ 'api/developer-apis/retirementPlanner/getSelectedRetirementPlannerAccount/',
Get_Retirement_Planner_Accounts: BudgetBuddie+ 'api/developer-apis/retirementPlanner/getRetirementPlannerAccount/',


  // Goals

  Add_Goals: BudgetBuddie + 'api/developer-apis/goals/addGoals/',
  Get_AllGoals: BudgetBuddie + 'api/developer-apis/goals/getGoals/',
  Delete_Goals: BudgetBuddie + 'api/developer-apis/goals/deleteGoals/',
  Update_Goals: BudgetBuddie + 'api/developer-apis/goals/updateGoals/',
  Get_Goals_ById: BudgetBuddie + 'api/developer-apis/goals/getGoalsByGoalUuid/',
  Get_Goals_ByBudget: BudgetBuddie + 'api/goals/getGoalByBudget/',

  // Merchant
  Get_Merchats_By_ClientId: BudgetBuddie + 'api/developer-apis/merchants/getMerchantsByClientIdWithFilter/',
  Get_Category: BudgetBuddie + 'api/merchants/getCategory/',
  Get_Top_Merchants: BudgetBuddie + 'api/merchants/getTopFiveMerchants/',
  Get_Top_Expenses: BudgetBuddie + 'api/merchants/getTopFiveExpenses/',
  Get_Transaction_By_Merchants_Id: BudgetBuddie + 'api/developer-apis/merchants/getTransactionsUsingMerchantId/',
  Update_Merchant_Category: BudgetBuddie + 'api/developer-apis/merchants/updateMerchantsCategory/',

  // Categories
  Get_All_Categories: BudgetBuddie + 'api/developer-apis/category/getAllCategoryByUserUuid/',
  Update_Category:BudgetBuddie + 'api/developer-apis/transactions/updateTransactionsCategory/',
  Delete_Expense_Category_And_Budget:BudgetBuddie + 'api/developer-apis/v1/budgetSummary/deleteExpenseCategoryAndBudget/',
  Remove_Category_By_UserUuid_And_CategoryId:BudgetBuddie + 'api/developer-apis/category/removeCategoryByUserUuidAndCategoryId/',
  Update_Category_In_Bulk:BudgetBuddie + 'api/developer-apis/transactions/updateBulkTransactionsCategory/',

  Add_Expense_Category: BudgetBuddie + 'api/developer-apis/category/addCustomExpenseCategoryByUserUuid/',
  Add_Income_Category: BudgetBuddie + 'api/developer-apis/category/addCustomIncomeCategoryByUserUuid/',
  Edit_Category: BudgetBuddie + 'api/developer-apis/category/updateCustomCategoryByUserUuidAndCategoryId/',


  // First time SignUp/Login
  Create_Help_Us_Help_You: BudgetBuddie + 'api/developer-apis/feedback/createHelpUsHelpYou/',
  Set_Setup: BudgetBuddie + 'api/developer-apis/user/setSetup/',


 
  // Trasactions 
  Get_Transaction_By_ClientId: BudgetBuddie + 'api/developer-apis/transactions/getTransactionsByClientIdWithFilter/',
  Add_Transaction: BudgetBuddie + 'api/developer-apis/transactions/addTransactions',
  Split_Transaction: BudgetBuddie + "api/developer-apis/transactions/splitTransaction/",
  Get_ALL_Transactions_Count: BudgetBuddie+ 'api/developer-apis/transactions/getTransactionsCategorizedByUserUuid/',
  Get_Latest_Transactions:  BudgetBuddieAkahuAPI+ 'akahuApi/transaction/getAkahuLatestTransactions/',
  Delete_Custom_Transactions:  BudgetBuddie+ 'api/developer-apis/transactions/deleteTransaction/',
  Split_Back_To_Main_Transaction:  BudgetBuddie+ 'api/developer-apis/transactions/splitbackToMainTransaction/',
  Split_Single_Back_To_Main_Transaction:  BudgetBuddie+ 'api/developer-apis/transactions/splitSingleBackToMainTransaction/',
  Delete_Duplicate_Transactions : BudgetBuddie+ 'api/developer-apis/transactions/deleteDuplicateTransaction/',

  // change password
  Change_Password: BudgetBuddie + 'api/developer-apis/clients/v1/user/changePassword',

// Forgot password
  Forgot_Password: BudgetBuddie + 'api/developer-apis/auth/v1/user/forgotPassword',
  Check_Reset_Password_Otp: BudgetBuddie + 'api/developer-apis/clients/v1/user/checkResetPasswordOTP',
  Forgot_Set_New_Password: BudgetBuddie + 'api/developer-apis/clients/v1/user/setNewPassword',

  // Revoke Account
  Revoke_account: BudgetBuddie + 'api/clients/revokeAccount/',

  // akahu account and transaction
  Get_Akahu_Transaction: BudgetBuddie + 'api/auth/akahu/getTransactions/',
  Akahu_Connection: BudgetBuddieAkahuAPI + 'akahuApi/account/',
  Akahu_First_Five_Day_Transactions: BudgetBuddieAkahuAPI + 'akahuApi/transaction/getFirst5dayTransactions/',
  Akahu_All_Transactions_Load: BudgetBuddieAkahuAPI +  'akahuApi/transaction/getWebVersionUserLoadAllTransactions/',
  Akahu_Basic_Plan_All_Transactions_Load: BudgetBuddieAkahuAPI +  'akahuApi/transaction/getWebVersionAkahuBasicUserLoadAllTransactions/',


  // Settings Apis
  Set_2Factor_Authenication: BudgetBuddie + 'api/developer-apis/clients/v1/user/email2faVerification',
  Set_2FaSetup_Authenication: BudgetBuddie + 'api/developer-apis/clients/v1/user/email2faSetupVerification',
  Revoke_2Factor_Authentication: BudgetBuddie + 'api/developer-apis/clients/v1/user/revokeEmail2faVerificationCode',
  Upload_User_Image: BudgetBuddie + 'api/developer-apis/user/changeUserProfile/',
  Enable_2Factor_Authentication: BudgetBuddie + "api/developer-apis/clients/v1/user/enabledEmail2faVerification",
  Get_Plan_Details: BudgetBuddie + 'api/developer-apis/plan/getPlanDetails',
  Check_Akahu_Token: BudgetBuddie +  'api/developer-apis/auth/akahu/checkAkahuToken/',
  Update_User_Status: BudgetBuddie +  'api/developer-apis/user/updateUserStatus/',
  User_Disabled: BudgetBuddie +  'api/developer-apis/user/userDisabled/',

  // One Off Budget

  Add_OneOffBudget: BudgetBuddie + 'api/oneOffBudget/createOneOffBudget/',
  Get_OneOffBudget: BudgetBuddie + 'api/oneOffBudget/getOneOffBudget/',
  Update_OneOffBudget: BudgetBuddie + 'api/oneOffBudget/updateOneOffBudget/',
  Delete_OneOffBudget: BudgetBuddie + 'api/oneOffBudget/deleteOneOffBudget/',
  Get_OneOffBudget_ById: BudgetBuddie + 'api/oneOffBudget/getOneOffBudgetByUuid/',

  // Forget password for all role
  Forget_Password: BudgetBuddie + 'api/clients/forgetPassword',
  Check_Reset_Password_Link: BudgetBuddie + 'api/clients/checkResetPassword',
  Set_New_Password: BudgetBuddie + 'api/clients/setNewPassword',
  Email_2FA_Verification: BudgetBuddie + 'api/clients/email2faVerification',
  Enabled_Email_2Fa_Verification: BudgetBuddie + 'api/clients/enabledEmail2faVerification',

  // Payment Plam
  Plan_payment_session: BudgetBuddie + 'api/plan/create-subscription-checkout',
  Cancel_current_plan: BudgetBuddie + 'api/plan/cancelCurrentPlan',
  Cancel_plan_feedback: BudgetBuddie + 'api/plan/cancelCurrentPlanFeedback',
  Update_plan_payment_session: BudgetBuddie + 'api/plan/update-subscription-checkout',
  Get_Subscription_id: BudgetBuddie + 'api/plan/getSubscriptionId',
  Get_Payment_Session_id: BudgetBuddie + 'api/plan/getPaymentSessionId',
  Payement_Success: BudgetBuddie + 'api/plan/payementSuccess',
  Payment_Detail_Success: BudgetBuddie + 'api/plan/updatePaymentDetailSuccess',
  Free_trail_plan: BudgetBuddie + 'api/plan/freeTrailPlan',
  Get_CurrentPlan: BudgetBuddie + 'api/plan/getPlanDetails',
  Get_Plan_Payment_Details: BudgetBuddie + 'api/plan/getPlanPaymentDetails',
  Update_Payment_Details: BudgetBuddie + 'api/plan/update-payment-detail',
  Free_Plan_Expire_Feedback: BudgetBuddie + 'api/plan/freePlanExpirefeedback',

  // Dashboard Api
  Dashboard_all_data: BudgetBuddie + 'api/dashboard/getAllDetails/',

  // Submit Feedback
  Submit_Feedback: BudgetBuddie + 'api/developer-apis/feedback/createFeedback/',

  // Sign Up Email
  Send_email_otp: BudgetBuddie + 'api/developer-apis/clients/v1/user/signupEmailVerification',
  Check_email_otp: BudgetBuddie + 'api/developer-apis/clients/v1/user/checkSignUPEmailVerification',
  OneTime_Login: BudgetBuddie + 'api/developer-apis/clients/v1/user/userPlanManageOneTimeLogin/',

  // Update User info
  Update_User: BudgetBuddie + 'api/developer-apis/user/updateUserDetails/',

  // Budget Tab Api
  Get_Income_Budget : BudgetBuddie + 'api/developer-apis/v1/budgetSummary/getIncomeVsBudgetDetails/',
  Get_Expense_Budget: BudgetBuddie + 'api/developer-apis/v1/budgetSummary/getSpendVsExpenseDetails/',
  Get_OneOff_Budget : BudgetBuddie + 'api/developer-apis/oneOffBudget/getOneOffBudget/',
  Get_Income_Transactions : BudgetBuddie + 'api/developer-apis/v1/budgetSummary/getIncomeTransactions/',
  Get_Expense_Transactions: BudgetBuddie + 'api/developer-apis/v1/budgetSummary/getExpenseTransactions/',
  Get_OneOff_Transactions: BudgetBuddie + 'api/developer-apis/oneOffBudget/getOneOffTransactions/',
  Add_Income_Budget: BudgetBuddie + 'api/developer-apis/v1/budgetSummary/addIncome/',
  Add_Expense_Budget: BudgetBuddie + 'api/developer-apis/v1/budgetSummary/addExpenses/',
  Get_Expense_Remaining_By_UserUuid: BudgetBuddie + 'api/developer-apis/category/getExpenseRemainingByUserUuid/',
  Get_Income_Remaining_By_UserUuid: BudgetBuddie + 'api/developer-apis/category/getIncomeRemainingByUserUuid/',
  Auto_Single_Budget_Expenses: BudgetBuddie + 'api/developer-apis/v1/budgetSummary/autoSingleBudgetExpenses/',
  Create_OneOff_Budget: BudgetBuddie + 'api/developer-apis/oneOffBudget/createOneOffBudget/',
  Update_Expense_Budget: BudgetBuddie + 'api/developer-apis/v1/budgetSummary/updateExpenses/',
  Update_Income_Budget: BudgetBuddie + 'api/developer-apis/v1/budgetSummary/updateIncome/',
  Update_OneOff_Budget: BudgetBuddie + 'api/developer-apis/oneOffBudget/updateOneOffBudget/',
  Delete_Expense_Budget: BudgetBuddie + 'api/developer-apis/v1/budgetSummary/expenses/',
  Delete_Expense_CategoryTransaction_Budget: BudgetBuddie + 'api/developer-apis/v1/budgetSummary/deleteExpenseCategoryAndTransaction/',
  Delete_Income_Budget: BudgetBuddie + 'api/developer-apis/v1/budgetSummary/deleteIncomes/',
  Delete_OneOff_Budget: BudgetBuddie + 'api/developer-apis/oneOffBudget/deleteOneOffBudget/',

  // Trnsaction Rule API
  Get_Transaction_rule: BudgetBuddie + 'api/developer-apis/transactions/getTransactionsRuleByUserUuid/',
  Add_Transaction_rule: BudgetBuddie + 'api/developer-apis/transactions/addTransactionsRule',
  Update_Transaction_rule: BudgetBuddie + 'api/developer-apis/transactions/updateTransactionsRuleByUserUuidAndRuleUuid/',
  Delete_Transaction_rule: BudgetBuddie + 'api/developer-apis/transactions/deleteTransactionRule/',
  Get_Transaction_rule_categorized: BudgetBuddie + 'api/developer-apis/transactions/getTransactionsRuleAndIncomeCategorizedByUserUuid/',
  Get_Credit_Transaction: BudgetBuddie + 'api/auth/akahu/getCreditTransactions/',
};
