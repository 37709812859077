import React, { useState } from "react";
import { ClipLoader } from "react-spinners";

const PopupRevokeConnections = ({
  isOpen,
  onClose,
  title,
  options,
  selectedOption,
  handleRevokeConnections,
}) => {

  const [loading, setLoading] = useState(false);

  const handleDisconnect = async (account, index) => {
    setLoading(true);
    try {
      await handleRevokeConnections(account);
    } catch (error) {
      console.error("Failed to disconnect account:", error);
    } finally {
      setLoading(false);
    }
  };


  return (
    isOpen && (
      <div className="modal-background open-modal">
        <div className="modal-content" style={{ width: "270px", gap: "8px" }}>
          <div>
            <div className="fs-3 revoke-title-flex">
              <span className="bold black popup-top-heading">
                Are you sure?
              </span>
              <span className="revoke-desc">This cannot be un-done.</span>
            </div>
          </div>
          <button
            onClick={() => onClose("revokeModal")}
            className="revoke-green-btn" style={{ marginTop: "10px"}}
          >
            Go back
          </button>
          <button className="revoke-red-btn" style={{ justifyItems: "center" }} onClick={handleDisconnect}>
            {loading ? (
               <div className="cupertino-loader"></div>
            ) : (
              "Revoke connections"
            )}
          </button>
        </div>
      </div>
    )
  );
};

export default PopupRevokeConnections;
