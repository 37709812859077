import React, { useEffect, useRef, useState } from "react";
import { ClipLoader } from "react-spinners";
import Tick from "../../assests/images/tick.svg";
import { ToastContainer } from "react-toastify";
import "./index.css";

// Placeholder mapping for reasons
const reasonPlaceholders = {
  "Too expensive": "How much would you pay?",
  "I need more features": "What features would you like to see?",
  "Customer service was less than expected": "How could we have been better?",
  "Ease of use was less than expected": "What did you find difficult to use?",
  "I found an alternative": "What alternative did you find?",
  "Lack of integration providers": "What providers were we missing?",
  Other: "Spill the beans here...",
};

// ReasonCheckbox component
const ReasonCheckbox = ({
  reason,
  isSelected,
  onClick,
  onTextChange,
  message,
}) => {
  const placeholder =
    reasonPlaceholders[reason] || "Please provide more details...";

  const handleTextChange = (e) => {
    onTextChange(reason, e.target.value);
  };

  return (
    <div>
      <div
        className={`feedback-reason-div-feedback ${
          isSelected
            ? "feedback-div-green-border-feedback"
            : "feedback-div-default-border-feedback"
        }`}
        onClick={() => onClick(reason)}
      >
        <label className="feedback-cancel-text">{reason}</label>
        <div className={`checkbox-round ${isSelected ? "selected" : ""}`}>
          {isSelected && <img src={Tick} alt="Tick" className="tick-icon" />}
        </div>
      </div>
      {isSelected && (
        <textarea
          value={message || ""}
          className="message-cancel-div"
          style={{
            paddingTop: "5px",
            paddingLeft: "5px",
            marginBottom: "5px",
            color: "#000",
          }}
          onChange={handleTextChange}
          placeholder={placeholder}
          required
        />
      )}
    </div>
  );
};

const SubmitFeedBackDeleteAccount = ({
  modalOpen,
  closeModal,
  onDeleteAccountWithFeedback,
}) => {
  const [loading, setLoading] = useState(false);
  const reasons = [
    "Too expensive",
    "I need more features",
    "Customer service was less than expected",
    "Ease of use was less than expected",
    "I found an alternative",
    "Lack of integration providers",
    "Other",
  ];
  const [selectedReason, setSelectedReason] = useState("");
  const [messages, setMessages] = useState({});

  const handleReasonClick = (reason) => {
    setSelectedReason((prev) => (prev === reason ? "" : reason));
  };

  const handleTextChange = (reason, value) => {
    setMessages((prevMessages) => ({
      ...prevMessages,
      [reason]: value,
    }));
  };

  return (
    <>
      <div
        className={`modal-background ${modalOpen ? "open-modal" : ""} `}
        onClick={() => closeModal("submitFeedBack")}
      >
        <ToastContainer position="top-right" autoClose={5000} closeOnClick />
        <div
          className="loginFormWrp mt-3 mb-5"
          onClick={(e) => e.stopPropagation()}
        >
          <p
            className="text-center welcomeText mt-3 mb-5"
            style={{
              fontSize: "30px",
            }}
          >
            Sorry to see you go 😕
          </p>
          <p className="text-center mt-1 tagtext">
            Before we deactivate your account, please select your main
            deactivation reason below.
          </p>
          <div className="">
            <div>
              {/* <p className="plan-selected-text">Current Plan:</p> */}
            </div>
            <div className="row">
              <div className="col-lg-12 account-assets-column box-radis-25">
                <form>
                  {reasons.map((reason) => (
                    <ReasonCheckbox
                      key={reason}
                      reason={reason}
                      isSelected={selectedReason === reason}
                      onClick={handleReasonClick}
                      onTextChange={handleTextChange}
                      message={messages[reason]}
                    />
                  ))}
                </form>
              </div>
              <div className="text-center mt-2 pt-2 mb-4">
                <button
                  type="submit"
                  className={`comn-btn verify-btn ${
                    loading ? "sign-btn-load" : "sign-btn"
                  }`}
                  style={{ width: "100%" }}
                  onClick={async () => {
                    setLoading(true);
                    const feedbackReason = messages[selectedReason];
                    const feedbackSelection = selectedReason;

                    await onDeleteAccountWithFeedback(
                      feedbackReason,
                      feedbackSelection
                    );
                    setLoading(false);
                  }}
                >
                  {loading ? (
                    <ClipLoader size={15} color={"#ffffff"} />
                  ) : (
                    "Submit feedback"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmitFeedBackDeleteAccount;