import React, { useCallback, useEffect, useRef, useState } from "react";
import ListingCategories from "./ListingCategories";
import moment from "moment";
import AddCategoriesModal from "../../ModalForm/TransactionModals/AddCategoriesModal.jsx";
import Amount from "../../Components/Amount.jsx";
import { ReactComponent as Split } from "../../assests/images/split.svg";
import { ReactComponent as Check } from "../../assests/images/icons/check.svg";
import { ReactComponent as UnCheck } from "../../assests/images/icons/uncheck.svg";
import {
  categoryBackgroundCss,
  categoryColorForAll,
} from "../../utilities/Statics/index.jsx";
import PendingTransactionModel from "../../ModalForm/TransactionModals/PendingTransactionModel.jsx";
import EditCategoriesModal from "../../ModalForm/TransactionModals/EditCategoriesModal.jsx";

export const TransactionListing = ({
  data,
  modalOpen,
  expenseCategories,
  updateTransactionCategory,
  addNewCategory,
  editCategory,
  incomeCategories,
  oneOffCategory,
  isSelectionMode,
  setSelectionMode,
  setSelectedForBulkUpdate,
  selectedForBulkUpdate,
  updateCategoriesInBulk,
  isAccountModule = false,
  categoryTitle,
  categorySubTitle,
  deleteCategoryConfirm,
}) => {
  const [showAddCatogory, setShowAddCatogory] = useState(false);
  const [isIncomeCategoryChange, setIsIncomeCategoryChange] = useState(false);
  const [EditAddCatogory, setEditAddCatogory] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [showPendingTransaction, setShowPendingTransaction] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState({});
  const [transactionType, setTransactionType] = useState();
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);

  const togglePopover = (transactionId, amount) => {
    setIsIncomeCategoryChange(amount > 0);
    setPopoverOpen((prev) => ({
      ...prev,
      [transactionId]: !prev[transactionId],
    }));

    setTransactionType(amount < 0 ? "Expense" : "Income");
    setIsEdit(false);
  };

  const handleAddCategory = () => {
    setShowAddCatogory(!showAddCatogory);
    setPopoverOpen({});
  };

  const editCategoryOnTap = () => {
    setEditAddCatogory(!EditAddCatogory);
    setPopoverOpen({});
  };
  const handlePendingTransaction = () => {
    setShowPendingTransaction(!showPendingTransaction);
  };

  const handleLongPress = useCallback(() => {
    if (!isAccountModule) {
      setSelectionMode(true);
    }
  }, [setSelectionMode, isAccountModule]);

  const handleCardSelect = (id, amount) => {
    if (!isAccountModule) {
      const type = amount < 0 ? "Expense" : "Income";
      setSelectedForBulkUpdate((prevSelected) => {
        const newSelected = {
          ...prevSelected,
          [type]: [...prevSelected[type]],
        };
        if (newSelected[type].includes(id)) {
          newSelected[type] = newSelected[type].filter((item) => item !== id);
        } else {
          newSelected[type].push(id);
        }
        return newSelected;
      });
    }
  };

  const handleMouseDown = (e) => {
    if (e.button === 0) {
      e.currentTarget.longPressTimer = setTimeout(handleLongPress, 500);
    }
  };

  const handleMouseUp = (e) => {
    if (e.currentTarget.longPressTimer) {
      clearTimeout(e.currentTarget.longPressTimer);
    }
  };

  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      className="card-list"
    >
      {Object.keys(data).map((date) => (
        <div key={date}>
          <div className="acc-wrap-heading">{date}</div>
          {data[date].map((transaction) => (
            <div className="merchant-box mt-3">
              <div
                className="d-flex w-75"
                key={transaction.transactionUuid}
                onClick={() => {
                  if (!isSelectionMode) {
                    modalOpen("viewTransaction", transaction);
                  }
                  if (transaction.status !== "Pending") {
                    handleCardSelect(
                      transaction.transactionUuid,
                      transaction.amount
                    );
                  }
                }}
              >
                <div className="img-box">
                  {isSelectionMode && transaction.status !== "Pending" ? (
                    <div>
                      {selectedForBulkUpdate[
                        transaction.amount < 0 ? "Expense" : "Income"
                      ].includes(transaction.transactionUuid) ? (
                        <Check />
                      ) : (
                        <UnCheck />
                      )}
                    </div>
                  ) : (
                    <img
                      src={
                        transaction.merchantLogo ?? transaction.accountAvatar
                      }
                      alt=""
                      style={{ borderRadius: "50%" }}
                    />
                  )}
                </div>
                <div className="ms-3">
                  <div className="text-merchant bold black">
                    {transaction?.merchantName ?? transaction.transactionName}
                  </div>
                  <div
                    className="small-text-merchant"
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                      fontStyle: "normal",
                    }}
                  >
                    <Amount amount={transaction.amount} />
                    <span className="black ms-1">
                      <span className="black">|</span>
                    </span>
                    <h4 className="small-text-merchant space black">
                      <div
                        className="setting_text_color-2"
                        style={{
                          color: "#9B9B9B",
                          fontWeight: "500",
                          fontSize: "14px",
                          fontStyle: "normal",
                        }}
                      >
                        {moment(transaction.dateTime).format("DD/MM/YYYY")}
                      </div>
                    </h4>
                    {transaction.status === "Pending" && (
                      <div className="pending-item">P</div>
                    )}
                    {transaction.isSplit && (
                      <Split
                        style={{
                          height: "15px",
                          width: "15px",
                          marginTop: "2px",
                          marginLeft: "5px",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="button-blck">
                <div
                  className="icon-tag"
                  style={{ marginBottom: "0px" }}
                  id={`Popover-${transaction.transactionUuid}`}
                  onClick={() =>
                    transaction.status === "Pending"
                      ? handlePendingTransaction()
                      : togglePopover(
                          transaction.transactionUuid,
                          transaction?.amount
                        )
                  }
                >
                  {transaction.masterCategoryName !== "Uncategorised" && (
                    <div
                      className={`icon-transaction-img ${
                        isHexColor(transaction.masterCategoryColour)
                          ? ""
                          : categoryBackgroundCss(
                              transaction.masterCategoryColour
                            )
                      }`}
                      style={{
                        background:
                          transaction.status === "Pending"
                            ? "white"
                            : categoryColorForAll(
                                transaction.masterCategoryColour
                              ),
                      }}
                    >
                      {transaction.status === "Pending"
                        ? "⏳"
                        : transaction.masterCategoryEmoji}
                    </div>
                  )}

                  <div className="content">
                    <h6 className="icon-content">
                      {transaction.status === "Pending"
                        ? "Pending"
                        : transaction.masterCategoryName}
                    </h6>
                  </div>
                </div>
                <div>
                  <ListingCategories
                    categories={
                      transaction?.amount < 0
                        ? expenseCategories
                        : isIncomeCategoryChange
                        ? incomeCategories
                        : expenseCategories
                    }
                    oneOffCategory={
                      transaction?.amount < 0
                        ? oneOffCategory
                        : isIncomeCategoryChange
                        ? []
                        : oneOffCategory
                    }
                    updateTransactionCategory={updateTransactionCategory}
                    transactionId={transaction.transactionUuid}
                    togglePopover={togglePopover}
                    popoverOpen={popoverOpen}
                    // showAddCatogory={showAddCatogory}
                    handleChange={handleAddCategory}
                    transactionType={
                      transaction?.amount < 0 ? "Expense" : "Income"
                    }
                    updateCategoriesInBulk={updateCategoriesInBulk}
                    isSelectionMode={isSelectionMode}
                    isPendingTransaction={transaction.status === "Pending"}
                    categoryTitle={categoryTitle}
                    categorySubTitle={categorySubTitle}
                    editCategoryOnTap={editCategoryOnTap}
                    setSelectedCategory={setSelectedCategory}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    categoryPopoverHeading={"Transaction categories"}
                    isIncomeCategoryChange={isIncomeCategoryChange}
                    setIsIncomeCategoryChange={setIsIncomeCategoryChange}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
      {showAddCatogory && (
        <AddCategoriesModal
          modalOpen={showAddCatogory}
          addNewCategory={addNewCategory}
          transactionType={transactionType}
          closeModal={handleAddCategory}
        />
      )}
      {EditAddCatogory && (
        <EditCategoriesModal
          modalOpen={EditAddCatogory}
          editCategory={editCategory}
          transactionType={transactionType}
          closeModal={editCategoryOnTap}
          selectedCategory={selectedCategory}
          setEditAddCatogory={setEditAddCatogory}
          deleteCategoryConfirm={deleteCategoryConfirm}
        />
      )}
      {showPendingTransaction && (
        <PendingTransactionModel
          modalOpen={showPendingTransaction}
          closeModal={handlePendingTransaction}
        />
      )}
    </div>
  );
};
