import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";
import { ReactComponent as Success } from "../../assests/images/icons/passwordSucess.svg";
import { ReactComponent as EyeClose } from "../../assests/images/eye-close-icon.svg";
import { ReactComponent as EyeOpen } from "../../assests/images/eye-open-icon.svg";
import { ReactComponent as AddRoundDelete } from "../../assests/images/icons/Add_round_delete_acc.svg";

import "../AddIncome/addIncome.css";
import "./index.css";

const DeleteAccount = ({
  modalOpen,
  closeModal,
  revokeConfirmEmail,
  setRevokeConfirmEmail,
  revokeConfirmPassword,
  setRevokeConfirmPassword,
  deleteAccount,
  setAccoundDeleted,
  accountDeleted,
  modalActionCompleted,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    if (setAccoundDeleted) {
      const intervalId = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(intervalId);
          }
          return prevCountdown - 1;
        });
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [setAccoundDeleted]);

  return (
    <div className={`modal-background ${modalOpen ? "open-modal" : ""} `}>
      <ToastContainer position="top-right" autoClose={5000} closeOnClick />
      <div
        className="modal-content delete-modal-width"
        style={{ boxShadow: "0px 5px 30px 0px #0000001A", paddingTop:"12px"
         }}
      >
        <div className="">
          {!modalActionCompleted ? (
            <>
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div className="">
                  <span className="password-heading">Delete Account</span>
                </div>
                <div>
                  {!modalActionCompleted && (
                    <button
                      onClick={() => closeModal("deleteAccount")}
                      className="close-pass-button"
                      style={{ position: "static", fontSize:"0px" }}
                    >
                      {/* <i class="fa fa-times"></i> */}
                      <AddRoundDelete />
                    </button>
                  )}
                </div>
              </div>
              <div className="delete-section-model mt-4 p-0">
                <form className="row flex">
                  <div className="field-form ">
                    <label className="label-text">Confirm your email</label>
                    <input
                      type="email"
                      name="revokeConfirmEmail"
                      value={revokeConfirmEmail}
                      className="edit-settings-house w-75 py-2 inter add-goal full-width-transaction"
                      style={{ border: "none" }}
                      onChange={(e) => {
                        setRevokeConfirmEmail(e.target.value);
                      }}
                    />
                  </div>
                  <div className="field-form">
                    <label className="label-text">Confirm password</label>
                    <div className="password-input-flex">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="revokeConfirmPassword"
                        value={revokeConfirmPassword}
                        className="edit-settings-house w-75 py-2 inter add-goal full-width-transaction"
                        style={{ border: "none", paddingRight: "50px" }}
                        onChange={(e) => {
                          setRevokeConfirmPassword(e.target.value);
                        }}
                        required
                      />
                      <div
                        className="show-password-delete-acc"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? <EyeOpen /> : <EyeClose />}
                      </div>
                    </div>
                  </div>
                  <p className="delete-account-para">
                    <b>NOTE:</b> Deleting your account will mean we remove all
                    stored data and remove your account connections that you
                    have set up. Once your accounts deleted you will not be able
                    to access BudgetBuddie unless you create a new account. Once
                    you confirm deletion, this cannot be un-done.
                  </p>
                  <div
                    class="modal-popup-btn-wrp"
                    style={{ marginTop: "35px", marginBottom: "10px" }}
                  >
                    <button
                      className="change-password-btn"
                      type="button"
                      onClick={deleteAccount}
                      style={{backgroundColor:"#D04F4F"}}
                    >
                      Confirm deletion & revoke access
                    </button>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <div className="sucess-container transition-delay">
              <div className="fs-3">
                <span className="password-heading">
                  Account deleted
                </span>
              </div>
              <p className="sucess-p">
                Your account has been deleted and all your data has been
                removed. You will be redirected back to the login screen.
              </p>
              <div className="success-img">
                <Success />
              </div>
              <div class="modal-popup-btn-wrp">
                <button
                  className={`change-password-btn ${
                    setAccoundDeleted ? "breathing-effect" : ""
                  }`}
                  type="button"
                  onClick={closeModal}
                >
                  Redirecting in {countdown}{" "}
                  {countdown === 1 ? "second" : "seconds"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
