import React, { useEffect, useRef } from "react";
import { ReactComponent as CrossIcon } from "../../assests/images/icons/cross-icon.svg";
import {
  categoryColorForAll,
  categoryBackgroundCss,
} from "../../utilities/Statics/index.jsx";
import { motion } from "framer-motion";

const SettingCategoryPopover = ({
  togglePopover,
  isOpen,
  handleCategoryClick,
  onClose,
  incomeCat,
  expenseCat,
}) => {
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);
  const popoverRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);
  return (
    <div
      className="modal-overlay"
      isOpen={isOpen}
      toggle={onClose}
      innerRef={popoverRef}
    >
      <motion.div
        className="modal-container"
        style={{
          maxWidth: "693px",
          padding: "20px",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.5 }}
      >
        <div className="transaction-categories mt-2 ">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "15px",
            }}
          >
            <div
              className="bold popover-live"
              style={{ fontSize: "22px" }}
            >
              Select a category to edit
            </div>
            <button
              onClick={() => togglePopover()}
              className="close-edit-category-button"
              style={{top:"0px", position:"static"}}
            >
              <CrossIcon />
            </button>
          </div>
          <div className="categories group-btn green-btn">
            <p
              style={{
                fontSize: "16px",
                fontWeight: "700",
                marginBottom: "5px",
              }}
            >
              Your income categories
            </p>
            <div className="categories group-btn green-btn ">
              {incomeCat &&
                incomeCat?.length > 0 &&
                incomeCat.map((it) => (
                  <div
                    key={it?.userCategoryId}
                    className="button-blck"
                    onClick={() => handleCategoryClick(it)}
                    style={{ marginRight: "10px" }}
                  >
                    <div className={`icon-tag`}>
                      {it?.name !== "Uncategorised" && (
                        <div
                          className={`icon-transaction-img ${
                            isHexColor(it?.colour)
                              ? ""
                              : categoryBackgroundCss(it?.colour)
                          }`}
                          style={{
                            backgroundColor: categoryColorForAll(it?.colour),
                          }}
                        >
                          {it?.emoji}
                        </div>
                      )}
                      <div className="content">
                        <h6 className={`icon-content hello`}>{it?.name}</h6>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="categories group-btn green-btn">
            <p
              style={{
                fontSize: "16px",
                fontWeight: "700",
                marginBottom: "5px",
              }}
            >
              Your expense categories
            </p>
            <div className="categories group-btn green-btn ">
              {expenseCat &&
                expenseCat?.length > 0 &&
                expenseCat.map((it) => (
                  <div
                    key={it?.userCategoryId}
                    className="button-blck"
                    onClick={() => handleCategoryClick(it)}
                    style={{ marginRight: "10px" }}
                  >
                    <div className={`icon-tag`}>
                      {it?.name !== "Uncategorised" && (
                        <div
                          className={`icon-transaction-img ${
                            isHexColor(it?.colour)
                              ? ""
                              : categoryBackgroundCss(it?.colour)
                          }`}
                          style={{
                            backgroundColor: categoryColorForAll(it?.colour),
                          }}
                        >
                          {it?.emoji}
                        </div>
                      )}
                      <div className="content">
                        <h6 className={`icon-content hello`}>{it?.name}</h6>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default SettingCategoryPopover;